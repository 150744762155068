import services from "@/http";
import store from "../../../store";

const namespaced = true;

export default {
  namespaced,
  state: {
    log_atividade: {},
    message: null
  },
  actions: {
    ActionSetLOG_ATIVIDADE({ commit }, payload) {
      commit("SET_LOG_ATIVIDADE", payload);
    },
    ActionPOSTLogAtividade({ dispatch }, payload) {
      //console.log(payload);
      let papel = payload.papel;
      let id = payload.id;
      let copiedForm = Object.assign({}, payload);
      delete copiedForm.id;
      if (papel === "PENSIONISTA" || papel === "SERVIDOR") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.log_atividades.createLogAtividadesUser(
              {
                id: id
              },
              copiedForm
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            //console.error(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetLogAtividadesPagination({ dispatch }, payload) {
      ////console.log(payload);
      //let id = payload.id;
      let papel = payload.papel;
      delete payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let payloadCopied = payload;
            let response = services.log_atividades.getLogAtividadesPaginationAdmin(
              payloadCopied
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetLogAtividadesByQuery({ dispatch }, payload) {
      //console.log(payload);
      /*
      papel,
      atividade,
      nomeusuario,
      email,
      rota,
      destino,
      papel_selected,
      data_atividade,
      noOfRecords,
      pageIndex*/

      let papel_USER = payload.papel;
      let idUsuario = payload.idUsuario;
      let atividade = payload.atividade;
      let papel = payload.papel_selected;
      let nomeusuario = payload.nomeusuario;
      let email = payload.email;
      let rota = payload.rota;
      let destino = payload.destino;
      let data_atividade = payload.data_atividade;
      let noOfRecords = payload.noOfRecords;
      let pageIndex = payload.pageIndex;
      if (papel_USER === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.log_atividades.getLogAtividadesByQueryAdmin(
              {
                q: {
                  atividade,
                  idUsuario,
                  nomeusuario,
                  email,
                  rota,
                  destino,
                  papel,
                  data_atividade,
                  noOfRecords,
                  pageIndex
                }
              }
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err);
            return reject(err.status);
          }
        });
      }
    }
  },
  mutations: {
    SET_LOG_ATIVIDADE(state, payload) {
      state.log_atividade = payload;
    }
  },
  getters: {
    logAtividadeState: state => {
      return state.log_atividade;
    }
  }
};
