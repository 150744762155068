export const papel = {
  path: "papel",
  name: "Pagina Papel",
  component: () => import(/* webpackChunkName: "papel" */ "./pages/Papel")
};

export const associacao_papel = {
  path: "papel/associacao/:id",
  name: "Associação de Papel",
  props: true,
  component: () =>
    import(/* webpackChunkName: "papel/associacao" */ "./pages/Associacao")
};

export default [papel, associacao_papel];
