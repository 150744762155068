import store from "../store";

export default async (to, from, next) => {
  if (
    to.name === "Recuperar Senha" ||
    to.name === "Registro" ||
    to.name === "Confirmar Email"
  ) {
    next();
  } else {
    if (to.name !== "login" && !store.getters["auth/hasToken"]) {
      document.title = `${to.name} - Sind Calculos`;
      try {
        await store.dispatch("auth/ActionCheckToken");
        next({ path: to.path });
      } catch (err) {
        //console.log(err);
        //setBearerToken("");
        //localStorage.removeItem("token");
        document.title = `login - Sind Calculos`;
        next({ name: "login" });
      }
    } else {
      if (to.name === "login" && store.getters["auth/hasToken"]) {
        document.title = `${to.name} - Sind Calculos`;
        try {
          //await store.dispatch("auth/ActionLoadSession")
          //console.log("2");
          next({ name: "Home" });
          //});
        } catch (err) {
          //console.log(err);
          document.title = `login - Sind Calculos`;
          next({ name: "login" });
        }
      } else {
        next();
      }
    }
  }
};
