import Vue from "vue";
import VueResource from "vue-resource";
import services from "./services";
import store from "../store";
import BASE_URL from "@/properties.js";

Vue.use(VueResource);

const http = Vue.http;

http.options.root = BASE_URL;

const setBearerToken = token => {
  http.headers.common["Authorization"] = `Bearer ${token}`;
  //console.log(http.headers);
};

export const interceptors = req => {
  return ({ status, url }) => {
    if (
      (status === 401 || status === 403 || status === 0) &&
      !url.includes("https://viacep.com.br/ws/")
    ) {
      try {
        //setBearerToken("");
        //localStorage.removeItem("token");
        store.dispatch("auth/ActionSignOut");
        window._Vue.$router.push({ name: "login" }).catch(err => {});
      } catch (err) {
        console.log(err);
        window._Vue.$router.push({ name: "login" }).catch(err => {});
      }
      window._Vue.$router.push({ name: "login" }).catch(err => {});
    }
  };
};

http.interceptors.push(interceptors);

Object.keys(services).map(service => {
  services[service] = Vue.resource("", {}, services[service]);
});

/*Vue.http.interceptors.push(
  function(request, next) {
    //Add JWT to all requests
    request.headers["Authorization"] =
      "Bearer: " + localStorage.getItem("token");
    //Skip storing token refresh requests
    console.log(equest.headers);
  }.bind(this)
);
*/
export default services;
export { http, setBearerToken };
// this.$http.get('/auth/')
