export const PROXY_URL = "https://proxier.now.sh/api?url=";

export default {
  getEnderecoByIdAdmin: {
    method: "get",
    url: "api/v1/admin/endereco{/id}"
  },
  getEnderecoAllAdmin: {
    method: "get",
    url: "api/v1/admin/endereco/"
  },
  deleteEnderecoByIdAdmin: {
    method: "delete",
    url: "api/v1/admin/endereco{/id}"
  },
  updateEnderecoAdmin: {
    method: "put",
    url: "api/v1/admin/endereco{/id}"
  },
  createEnderecoAdmin: {
    method: "post",
    url: "api/v1/admin/endereco/"
  },
  getEnderecoFromServAdmin: {
    method: "get",
    url: "api/v1/admin/endereco/from_serv{/id}"
  },
  getEnderecoFromPenAdmin: {
    method: "get",
    url: "api/v1/admin/endereco/from_pen{/id}"
  },
  getCEP: {
    method: "get",
    url: "https://proxier.now.sh/api?url=https://viacep.com.br/ws{/id}/json"
    //url: "https://viacep.com.br/ws{/id}/json"
  },
  getEnderecoFromServAdminEntidade: {
    method: "post",
    url: "api/v1/admin_entidade/endereco/from_serv/"
  },
  getEnderecoFromPenAdminEntidade: {
    method: "post",
    url: "api/v1/admin_entidade/endereco/from_pen/"
  }
};
