export default {
  getEmailByIdAdmin: {
    method: "get",
    url: "api/v1/admin/email{/id}"
  },
  getEmailAllAdmin: {
    method: "get",
    url: "api/v1/admin/email/"
  },
  deleteEmailByIdAdmin: {
    method: "delete",
    url: "api/v1/admin/email{/id}"
  },
  updateEmailAdmin: {
    method: "put",
    url: "api/v1/admin/email{/id}"
  },
  createEmailAdmin: {
    method: "post",
    url: "api/v1/admin/email/"
  },
  getEmailFromServAdmin: {
    method: "get",
    url: "api/v1/admin/email/from_serv{/id}"
  },
  getEmailFromPenAdmin: {
    method: "get",
    url: "api/v1/admin/email/from_pen{/id}"
  },
  getEmailFromServAdminEntidade: {
    method: "post",
    url: "api/v1/admin_entidade/email/from_serv/"
  },
  getEmailFromPenAdminEntidade: {
    method: "post",
    url: "api/v1/admin_entidade/email/from_pen/"
  }
};
