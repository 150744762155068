export default {
  getServidorPensionistaByIdAdmin: {
    method: "get",
    url: "api/v1/admin/servidor_pensionista{/id}"
  },
  deleteServidorPensionistaByIdAdmin: {
    method: "delete",
    url: "api/v1/admin/servidor_pensionista{/id}"
  },
  createServidorPensionistaAdmin: {
    method: "post",
    url: "api/v1/admin/servidor_pensionista/"
  },
  updateServidorPensionistaAdmin: {
    method: "put",
    url: "api/v1/admin/servidor_pensionista{/id}"
  },
  getServidorPensionistaToAssingmentFromEntidadeAdmin: {
    method: "post",
    url:
      "api/v1/admin/servidor_pensionista/list_servidor_pensionista_assingment/all/"
  },
  getServidorFromPenAdmin: {
    method: "get",
    url: "api/v1/admin/servidor_pensionista/from_pen{/id}"
  },
  getServidorFromPenUser: {
    method: "post",
    url: "api/v1/user/pensionista/servidor_pensionista/"
  },
  getServidorPensionistaByIdAdminEntidade: {
    method: "post",
    url: "api/v1/admin_entidade/servidor_pensionista/"
  },
  getServidorFromPenAdminEntidade: {
    method: "post",
    url: "api/v1/admin_entidade/servidor_pensionista/from_pen/"
  },
  getServidorPensionistaToAssingmentFromEntidadeAdminEntidade: {
    method: "post",
    url:
      "api/v1/admin_entidade/servidor_pensionista/list_servidor_pensionista_assingment/all/"
  }
};
