import { services as auth } from "@/modules/auth";
import { services as user } from "@/modules/user";
import { services as entidade } from "@/modules/entidade";
import { services as papel } from "@/modules/papel";
import { services as servidor_pensionista } from "@/modules/servidor_pensionista";
import { services as servidor_publico } from "@/modules/servidor_publico";
import { services as pensionista } from "@/modules/pensionista";
import { services as dependente } from "@/modules/dependente";
import { services as endereco } from "@/modules/endereco";
import { services as telefone } from "@/modules/telefone";
import { services as email } from "@/modules/email";
import { services as arquivo } from "@/modules/arquivo";
import { services as pensionista_quotaparte } from "@/modules/pensionista_quotaparte";
import { services as historico_vinculos } from "@/modules/historico_vinculos";
import { services as log_atividades } from "@/modules/log_atividades";
import { services as ficha_financeira } from "@/modules/ficha_financeira";

export default {
  auth,
  user,
  entidade,
  papel,
  servidor_pensionista,
  servidor_publico,
  pensionista,
  dependente,
  endereco,
  telefone,
  email,
  arquivo,
  pensionista_quotaparte,
  historico_vinculos,
  log_atividades,
  ficha_financeira
};
