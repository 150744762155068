export default {
  getPensionistaByIdAdmin: {
    method: "get",
    url: "api/v1/admin/pensionista{/id}"
  },
  getPensionistaAllAdmin: {
    method: "get",
    url: "api/v1/admin/pensionista/"
  },
  deletePensionistaByIdAdmin: {
    method: "delete",
    url: "api/v1/admin/pensionista{/id}"
  },
  updatePensionistaAdmin: {
    method: "put",
    url: "api/v1/admin/pensionista{/id}"
  },
  createPensionistaAdmin: {
    method: "post",
    url: "api/v1/admin/pensionista/"
  },
  getPensionistasByNomeCompletoOrCpf: {
    method: "get",
    url: "api/v1/admin/pensionista/search{/id}{/query}"
  },
  getPensionistasPaginationAdmin: {
    method: "post",
    url: "api/v1/admin/pensionista/pagination"
  },
  getPensionistasByQueryAdmin: {
    method: "get",
    url: "api/v1/admin/pensionista/search/query{?q*}"
  },
  getPensionistaByIdUser: {
    method: "post",
    url: "api/v1/user/pensionista/"
  },
  getPensionistaByIdAdminEntidade: {
    method: "post",
    url: "api/v1/admin_entidade/pensionista/"
  },
  getPensionistasPaginationAdminEntidade: {
    method: "post",
    url: "api/v1/admin_entidade/pensionista/pagination"
  },
  getPensionistasByQueryAdminEntidade: {
    method: "get",
    url: "api/v1/admin_entidade/pensionista/search/query{?q*}"
  }
};
