var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper",class:[
    { 'nav-open': _vm.$sidebar.showSidebar },
    { rtl: _vm.$route.meta.rtlActive }
  ]},[_c('notifications'),_c('side-bar',{attrs:{"active-color":_vm.sidebarBackground,"background-image":_vm.sidebarBackgroundImage,"data-background-color":_vm.sidebarBackgroundColor}},[_c('user-menu',{attrs:{"title":_vm.getUsernameProfile()}}),_c('mobile-menu'),_c('template',{slot:"links"},[(
          (_vm.papel === 'ADMIN' ||
            _vm.papel === 'ADMIN_ENTIDADE' ||
            _vm.papel === 'PENSIONISTA' ||
            _vm.papel === 'SERVIDOR') &&
            _vm.emailConfirmado
        )?_c('sidebar-item',{attrs:{"link":{ name: 'Dashboard', icon: 'dashboard', path: '/dashboard' }}}):_vm._e(),(_vm.papel === 'ADMIN' && _vm.emailConfirmado)?_c('sidebar-item',{attrs:{"link":{
          name: 'Entidades',
          icon: 'account_balance',
          path: '/entidade'
        }}}):_vm._e(),(_vm.papel === 'ADMIN' && _vm.emailConfirmado)?_c('sidebar-item',{attrs:{"link":{
          name: 'Papeis',
          icon: 'assignment_ind',
          path: '/papel'
        }}}):_vm._e(),(
          (_vm.papel === 'ADMIN' || _vm.papel === 'ADMIN_ENTIDADE') && _vm.emailConfirmado
        )?_c('sidebar-item',{attrs:{"link":{
          name: 'Usuários',
          icon: 'supervisor_account',
          path: '/usuarios'
        }}}):_vm._e(),(
          (_vm.papel === 'ADMIN' || _vm.papel === 'ADMIN_ENTIDADE') && _vm.emailConfirmado
        )?_c('sidebar-item',{attrs:{"link":{
          name: 'Servidores',
          icon: 'folder_shared',
          path: '/servidores_publicos'
        }}}):(_vm.papel === 'SERVIDOR' && _vm.emailConfirmado)?_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkIfIdServidor()),expression:"checkIfIdServidor()"}],attrs:{"link":{
          name: 'Servidor',
          icon: 'folder_shared',
          path: `/servidor_view/${_vm.idServidor}`
        }}}):_vm._e(),(
          (_vm.papel === 'ADMIN' || _vm.papel === 'ADMIN_ENTIDADE') && _vm.emailConfirmado
        )?_c('sidebar-item',{attrs:{"link":{
          name: 'Pensionistas',
          icon: 'escalator_warning',
          path: '/pensionistas'
        }}}):(_vm.papel === 'PENSIONISTA' && _vm.emailConfirmado)?_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkIfIdPensionista()),expression:"checkIfIdPensionista()"}],attrs:{"link":{
          name: 'Pensionista',
          icon: 'escalator_warning',
          path: `/pensionista_view/${_vm.idPensionista}`
        }}}):_vm._e(),(_vm.papel === 'ADMIN' && _vm.emailConfirmado)?_c('sidebar-item',{attrs:{"link":{
          name: 'Import./Export.',
          icon: 'cloud_upload',
          path: '/importar'
        }}}):_vm._e(),(_vm.papel === 'ADMIN' && _vm.emailConfirmado)?_c('sidebar-item',{attrs:{"link":{
          name: 'Ficha Financeira',
          icon: 'request_quote',
          path: '/exportar_ficha_financeira'
        }}}):_vm._e(),(_vm.papel === 'ADMIN' && _vm.emailConfirmado)?_c('sidebar-item',{attrs:{"link":{
          name: 'Log Atividade',
          icon: 'description',
          path: '/log_atividades'
        }}}):_vm._e(),(_vm.papel === 'ADMIN_ENTIDADE' && _vm.emailConfirmado)?_c('sidebar-item',{attrs:{"link":{
          name: 'Arquivos',
          icon: 'text_snippet',
          path: `/arquivos/entidade/${_vm.idEntidade}`
        }}}):_vm._e()],1)],2),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }