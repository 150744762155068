export default {
  getDependenteByIdAdmin: {
    method: "get",
    url: "api/v1/admin/dependente{/id}"
  },
  getDependenteAllAdmin: {
    method: "get",
    url: "api/v1/admin/dependente/"
  },
  deleteDependenteByIdAdmin: {
    method: "delete",
    url: "api/v1/admin/dependente{/id}"
  },
  updateDependenteAdmin: {
    method: "put",
    url: "api/v1/admin/dependente{/id}"
  },
  createDependenteAdmin: {
    method: "post",
    url: "api/v1/admin/dependente/"
  },
  createServidorDependenteAdmin: {
    method: "post",
    url: "api/v1/admin/servidor_dependente/"
  },
  getServidorDependenteAdmin: {
    method: "get",
    url: "api/v1/admin/servidor_dependente/from_serv{/id}"
  },
  updateServidorDependenteAdmin: {
    method: "put",
    url: "api/v1/admin/servidor_dependente{/id}"
  },
  deleteServidorDependenteByIdAdmin: {
    method: "delete",
    url: "api/v1/admin/servidor_dependente{/id}"
  },
  getDependentesByNomeCompletoOrCpf: {
    method: "get",
    url: "api/v1/admin/dependente/search{/id}{/query}"
  },
  getServidorDependenteAdminEntidade: {
    method: "post",
    url: "api/v1/admin_entidade/servidor_dependente/from_serv/"
  }
};
