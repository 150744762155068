export default [
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "login" */ "./pages/Login")
  },
  {
    path: "/recuperar_senha/:token",
    name: "Recuperar Senha",
    props: true,
    component: () =>
      import(/* webpackChunkName: "recuperar_senha" */ "./pages/RecuperarSenha")
  },
  {
    path: "/registro",
    name: "Registro",
    props: true,
    component: () =>
      import(/* webpackChunkName: "registro" */ "./pages/Register")
  },
  {
    path: "/confirmar_email/:token",
    name: "Confirmar Email",
    props: true,
    component: () =>
      import(/* webpackChunkName: "confirmar_email" */ "./pages/ConfirmarEmail")
  },
  {
    path: "/termos_condicoes/",
    name: "Termos Condições",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "confirmar_email" */ "./pages/TermosCondicoes"
      )
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import(/* webpackChunkName: "login" */ "./pages/Logout")
  }
];
