export default {
  getTelefoneByIdAdmin: {
    method: "get",
    url: "api/v1/admin/telefone{/id}"
  },
  getTelefoneAllAdmin: {
    method: "get",
    url: "api/v1/admin/telefone/"
  },
  deleteTelefoneByIdAdmin: {
    method: "delete",
    url: "api/v1/admin/telefone{/id}"
  },
  updateTelefoneAdmin: {
    method: "put",
    url: "api/v1/admin/telefone{/id}"
  },
  createTelefoneAdmin: {
    method: "post",
    url: "api/v1/admin/telefone/"
  },
  getTelefoneFromServAdmin: {
    method: "get",
    url: "api/v1/admin/telefone/from_serv{/id}"
  },
  getTelefoneFromPenAdmin: {
    method: "get",
    url: "api/v1/admin/telefone/from_pen{/id}"
  },
  getTelefoneFromServAdminEntidade: {
    method: "post",
    url: "api/v1/admin_entidade/telefone/from_serv/"
  },
  getTelefoneFromPenAdminEntidade: {
    method: "post",
    url: "api/v1/admin_entidade/telefone/from_pen/"
  }
};
