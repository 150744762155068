export default {
  getImportarByIdAdmin: {
    method: "get",
    url: "api/v1/admin/importar{/id}"
  },
  getImportarAllAdmin: {
    method: "get",
    url: "api/v1/admin/importar/"
  },
  deleteImportarByIdAdmin: {
    method: "delete",
    url: "api/v1/admin/importar{/id}"
  },
  updateImportarAdmin: {
    method: "put",
    url: "api/v1/admin/importar{/id}"
  },
  createImportarAdmin: {
    method: "post",
    url: "api/v1/admin/importar/"
  },
  getImportarFromServAdmin: {
    method: "get",
    url: "api/v1/admin/importar/from_serv{/id}"
  },
  getImportarFromPenAdmin: {
    method: "get",
    url: "api/v1/admin/importar/from_pen{/id}"
  }
};
