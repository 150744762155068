import services from "@/http";
import * as storage from "../storage";
import * as types from "./mutation-types";
import * as getters from "./getters";
import store from "../../../store";
import { setBearerToken } from "@/http";

export const ActionDoLogin = ({ dispatch }, payload) => {
  return services.auth.login(payload).then(res => {
    //dispatch("ActionGetSelfid", res.data.jwt);
    dispatch("ActionSetToken", res.data.jwt);
  });
};

export const ActionCheckToken = ({ dispatch, state }) => {
  if (state.jwt) {
    return Promise.resolve(state.jwt);
  }

  const token = storage.getLocalToken();
  //console.log(token);

  if (!token) {
    return Promise.reject(new Error("Token Inválido"));
  }
  dispatch("ActionSetToken", token);
  return dispatch("ActionLoadSession", token);
};

export const ActionLoadSession = ({ dispatch }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await services.auth.loadSession();
      //console.log(response);
      resolve(dispatch("ActionSetUserProfile", response.body));
    } catch (err) {
      //storage.setHeaderToken("");
      //storage.deleteLocalToken();
      //console.log(err.status);
      //window._Vue.$router.push({ name: "login" }).catch(err => {});
      return reject(err.status);
    }
  });
};

/*
export const ActionGetSelfid = ({ dispatch }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const {
        data: { user }
      } = await services.auth.selfid();
      console.log(user);
      //vdispatch("ActionSetUser", user);
      resolve();
    } catch (err) {
      dispatch("ActionSignOut");
      reject(err);
    }
  });
};
*/

export const ActionSolicitacaoCadastro = ({ dispatch }, payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await services.auth.solicitacaoCadastro(payload.form);
      resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
};

export const ActionConfirmarEmail = ({ dispatch }, payload) => {
  let token = payload.token;
  setBearerToken(token);
  return new Promise(async (resolve, reject) => {
    try {
      let response = await services.auth.confirmarEmail();
      resolve(response);
    } catch (err) {
      //storage.setHeaderToken("");
      return reject(err);
    }
  });
};

export const ActionRegistro = ({ dispatch }, payload) => {
  let copiedForm = Object.assign({}, payload.form);
  copiedForm.cpfUsuario =
    copiedForm.cpfUsuario === null
      ? null
      : Number(
          copiedForm.cpfUsuario
            .toString()
            .replace(/\./g, "")
            .replace(/-/g, "")
        );

  copiedForm.termosCondicoes = copiedForm.termosCondicoes === true ? "1" : "0";
  return new Promise(async (resolve, reject) => {
    try {
      let response = await services.auth.registro(copiedForm);
      resolve(response);
    } catch (err) {
      //storage.setHeaderToken("");
      return reject(err);
    }
  });
};

export const ActionEsqueciSenha = ({ dispatch }, payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let form = payload;
      let response = await services.auth.esqueciSenha(form);
      storage.deleteLocalToken();
      resolve(response);
    } catch (err) {
      //storage.setHeaderToken("");
      storage.deleteLocalToken();
      return reject(err);
    }
  });
};

export const ActionRecuperarSenha = ({ dispatch }, payload) => {
  let token = payload.token;
  let form = payload.form;
  setBearerToken(token);
  return new Promise(async (resolve, reject) => {
    try {
      let response = await services.auth.recuperarSenha(form);
      resolve(response);
    } catch (err) {
      //storage.setHeaderToken("");
      return reject(err);
    }
  });
};

export const ActionPUTPerfilAtual = ({ dispatch }, payload) => {
  let id = payload.idUsuario;
  let papel = payload.papel;
  let body = payload.body;
  return new Promise(async (resolve, reject) => {
    try {
      let response = services.auth.perfilAtual({ id: id }, body);
      //console.log(response);
      resolve(response);
    } catch (err) {
      console.log(err.status);
      return reject(err.status);
    }
  });
};

export const ActionPOSTReenviarEmail = ({ dispatch }, payload) => {
  let papel = payload.papel;
  let idUsuario = payload.idUsuario;
  return new Promise(async (resolve, reject) => {
    try {
      let response = services.auth.reenviarEmail({ idUsuario: idUsuario });
      //console.log(response);
      resolve(response);
    } catch (err) {
      console.log(err.status);
      return reject(err.status);
    }
  });
};

export const ActionValidarToken = ({ dispatch }, payload) => {
  let token = payload;
  setBearerToken(token);
  return new Promise(async (resolve, reject) => {
    try {
      let response = await services.auth.validarToken();
      resolve(response);
    } catch (err) {
      //storage.setHeaderToken("");
      return reject(err);
    }
  });
};

export const ActionSetIdPensionistaPerfil = ({ commit }, payload) => {
  commit(types.SET_ID_PENSIONISTA_PERFIL, payload);
};

export const ActionSetIdServidorPerfil = ({ commit }, payload) => {
  commit(types.SET_ID_SERVIDOR_PERFIL, payload);
};

export const ActionSetIdUsuarioEntidade = ({ commit }, payload) => {
  commit(types.SET_ID_USUARIO_ENTIDADE, payload);
};

export const ActionSetUserProfile = ({ commit }, payload) => {
  commit(types.SET_USER_PROFILE, payload);
};

export const ActionSetToken = ({ commit }, payload) => {
  storage.setLocalToken(payload);
  storage.setHeaderToken(payload);
  commit(types.SET_TOKEN, payload);
};

export const ActionSignOut = ({ dispatch }) => {
  //console.log("logout");
  storage.setHeaderToken("");
  storage.deleteLocalToken();
  dispatch("ActionSetUserProfile", {});
  dispatch("ActionSetToken", "");
  //console.log("logout");
};
