export default {
  getHistoricoVinculosByIdAdmin: {
    method: "get",
    url: "api/v1/admin/historico_vinculos{/id}"
  },
  deleteHistoricoVinculosByIdAdmin: {
    method: "delete",
    url: "api/v1/admin/historico_vinculos{/id}"
  },
  updateHistoricoVinculosAdmin: {
    method: "put",
    url: "api/v1/admin/historico_vinculos{/id}"
  },
  createHistoricoVinculosAdmin: {
    method: "post",
    url: "api/v1/admin/historico_vinculos/"
  },
  getHistoricoVinculosFromServAdmin: {
    method: "get",
    url: "api/v1/admin/historico_vinculos/from_serv{/id}"
  }
};
