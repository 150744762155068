import services from "@/http";
import store from "../../../store";

const namespaced = true;

export default {
  namespaced,
  state: {
    dependente: {},
    message: null
  },
  actions: {
    ActionGetDependenteById({ dispatch }, payload) {
      //console.log(payload);
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.dependente.getDependenteByIdAdmin({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetDependenteAll({ dispatch }, payload) {
      //console.log(payload);
      //let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.dependente.getServidoresPublicoAllAdmin();
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionSetDEPENDENTE({ commit }, payload) {
      commit("SET_DEPENDENTE", payload);
    },
    ActionPUTDependente({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      let copiedForm = Object.assign({}, payload.dependente);
      Object.keys(copiedForm).map(key => {
        return (copiedForm[key] =
          copiedForm[key] === null
            ? null
            : copiedForm[key].toString().toUpperCase());
      });
      copiedForm.cpfDependente =
        copiedForm.cpfDependente === null
          ? null
          : Number(
              copiedForm.cpfDependente
                .toString()
                .replace(/\./g, "")
                .replace(/-/g, "")
            );
      // delete copiedForm.idServidor;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.dependente.updateDependenteAdmin(
              { id: id },
              copiedForm
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionPOSTDependente({ dispatch }, payload) {
      let papel = payload.papel;
      let copiedForm = Object.assign({}, payload.dependente);

      Object.keys(copiedForm).map(key => {
        return (copiedForm[key] =
          copiedForm[key] === null
            ? null
            : copiedForm[key].toString().toUpperCase());
      });

      copiedForm.cpfDependente =
        copiedForm.cpfDependente === null
          ? null
          : Number(
              copiedForm.cpfDependente
                .toString()
                .replace(/\./g, "")
                .replace(/-/g, "")
            );
      //console.log(copiedForm);
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.dependente.createDependenteAdmin(
              copiedForm
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionDELETEDependente({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.dependente.deleteDependenteByIdAdmin({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetServidorDependenteFromServById({ dispatch }, payload) {
      let papel = payload.papel;
      let id = payload.id;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.dependente.getServidorDependenteAdmin({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "ADMIN_ENTIDADE") {
        return new Promise(async (resolve, reject) => {
          try {
            let idEntidade = payload.idEntidade;
            let response = services.dependente.getServidorDependenteAdminEntidade(
              {
                id: id,
                idEntidade: idEntidade
              }
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionDELETEServidorDependente({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.dependente.deleteServidorDependenteByIdAdmin(
              {
                id: id
              }
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetDependentesByNomeCompletoOrCpf({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      let query = payload.query;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.dependente.getDependentesByNomeCompletoOrCpf(
              {
                id: id,
                query: query
              }
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionPOSTServidorDependente({ dispatch }, payload) {
      let papel = payload.papel;
      let copiedForm = Object.assign({}, payload.servidorDependente);

      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.dependente.createServidorDependenteAdmin(
              copiedForm
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionPUTServidorDependente({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      let copiedForm = Object.assign({}, payload.servidorDependente);
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.dependente.updateServidorDependenteAdmin(
              { id: id },
              copiedForm
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    }
  },

  mutations: {
    SET_DEPENDENTE(state, payload) {
      state.dependente = payload;
    }
  },
  getters: {
    dependenteState: state => {
      return state.dependente;
    }
  }
};
