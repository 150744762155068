import services from "@/http";
import store from "../../../store";

const namespaced = true;

export default {
  namespaced,
  state: {
    importar: {},
    message: null
  },
  actions: {
    ActionGetImportarById({ dispatch }, payload) {
      //console.log(payload);
      var id = payload.id;
      var papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            var response = services.email.getImportarByIdAdmin({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.error(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetImportarAll({ dispatch }, payload) {
      //console.log(payload);
      //var id = payload.id;
      var papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            var response = services.email.getServidoresPublicoAllAdmin();
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.error(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionSetIMPORTAR({ commit }, payload) {
      commit("SET_IMPORTAR", payload);
    },
    ActionPUTImportar({ dispatch }, payload) {
      var id = payload.id;
      var papel = payload.papel;
      var copiedForm = Object.assign({}, payload.email);

      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            var response = services.email.updateImportarAdmin(
              { id: id },
              copiedForm
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.error(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionPOSTImportar({ dispatch }, payload) {
      var papel = payload.papel;
      var copiedForm = Object.assign({}, payload.email);

      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            var response = services.email.createImportarAdmin(copiedForm);
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.error(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetImportarFromServ({ dispatch }, payload) {
      //console.log(payload);
      var id = payload.id;
      var papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            var response = services.email.getImportarFromServAdmin({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.error(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetImportarFromPen({ dispatch }, payload) {
      //console.log(payload);
      var id = payload.id;
      var papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            var response = services.email.getImportarFromPenAdmin({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.error(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionDELETEImportar({ dispatch }, payload) {
      var id = payload.id;
      var papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            var response = services.email.deleteImportarByIdAdmin({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.error(err.status);
            return reject(err.status);
          }
        });
      }
    }
  },
  mutations: {
    SET_IMPORTAR(state, payload) {
      state.importar = payload;
    }
  },
  getters: {
    importarState: state => {
      return state.importar;
    }
  }
};
