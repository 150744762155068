export default {
  getEntidadeByIdAdmin: {
    method: "get",
    url: "api/v1/admin/entidade{/id}"
  },
  getEntidadeAllAdmin: {
    method: "get",
    url: "api/v1/admin/entidade/"
  },
  deleteEntidadeByIdAdmin: {
    method: "delete",
    url: "api/v1/admin/entidade{/id}"
  },
  updateEntidadeAdmin: {
    method: "put",
    url: "api/v1/admin/entidade{/id}"
  },
  createEntidadeAdmin: {
    method: "post",
    url: "api/v1/admin/entidade/"
  },
  getEntidadeAllAdminEntidade: {
    method: "get",
    url: "api/v1/admin_entidade/entidade{/id}"
  },
  getEntidadeByIdAdminEntidade: {
    method: "get",
    url: "api/v1/admin_entidade/entidade{/id}"
  }
};
