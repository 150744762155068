<template>
  <div class="md-layout">
    <notifications></notifications>
    <div
      class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-50"
      v-show="!showCards"
    >
      <md-card>
        <md-card-header>
          <div class="md-title">Verificação do Email :</div>
        </md-card-header>

        <md-card-content>
          <p class="category" v-bind:style="styleObjectEmail">
            Situação: {{ emailConfirmado
            }}<md-icon style="color: red;">cancel</md-icon>
          </p>
          Para poder acessar o conteúdo do site é preciso verificar seu email
          através do email enviado.
        </md-card-content>

        <md-card-actions>
          <md-button class="md-raised md-success mt-4" @click="reEnviarEmail()">
            Verificar Email
          </md-button>
        </md-card-actions>
      </md-card>
    </div>
    <div
      v-show="showCards"
      v-for="item in user_profile_state.usuarioEntidade"
      :key="item.idUsuarioEntidade"
      :style="[
        item.idEntidade === user_profile_state.idEntidade &&
        item.papel === user_profile_state.papel
          ? {
              border: '0px solid green',
              'border-radius': '20px',
              'background-color': 'lightgreen',
              height: '50%'
            }
          : { border: 'none' }
      ]"
      class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
    >
      <stats-card header-color="green">
        <template slot="header">
          <div class="card-icon">
            <md-icon>store</md-icon>
          </div>
          <h5 class="title h-title">
            Perfil
          </h5>
          <p class="category">
            Nome Papel
          </p>
          <h5 class="title h-title">
            {{ item.papel }}
          </h5>
          <p class="category">
            Nome Entidade
          </p>
          <h5 class="title h-title">{{ item.nomeEntidade }}</h5>
        </template>

        <template slot="footer">
          <div class="stats">
            <md-button
              class="md-raised md-success mt-4"
              @click="selecionarEntidade(item)"
            >
              Selecionar Perfil
            </md-button>
          </div>
        </template>
      </stats-card>
    </div>

    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33"
    >
      <md-card
        md-with-hover
        v-show="
          (this.user_profile_state.papel === 'SERVIDOR' ||
            this.user_profile_state.papel === 'PENSIONISTA') &&
          showCards
            ? true
            : false
        "
      >
        <md-card-header>
          <div class="md-title">
            {{
              this.user_profile_state.papel === "SERVIDOR"
                ? "SERVIDOR"
                : "PENSIONISTA"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <p>Nome Completo: {{ card.nomeCompleto }}</p>
          <p>CPF: {{ card.cpf }}</p>
          <p>SIAPE: {{ card.siape }}</p>
        </md-card-content>

        <md-card-actions>
          <div class="stats" style="padding-right:5px">
            <md-icon>access_time</md-icon>
            atualizado {{ card.dataModificacao }}
          </div>
          <md-button
            v-if="this.user_profile_state.papel === 'PENSIONISTA'"
            @click="chamarPensionistaView(card.id)"
            >Visualizar</md-button
          >
          <md-button
            v-if="this.user_profile_state.papel === 'SERVIDOR'"
            @click="chamarServidorView(card.id)"
            >Visualizar</md-button
          >
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AsyncWorldMap from "@/components/WorldMap/AsyncWorldMap.vue";
import { Modal } from "@/components";
import Swal from "sweetalert2";

const sleep = milliseconds => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};

import { StatsCard } from "@/components";
//:style="border: 2px solid green; border-radius: 25px;"
export default {
  components: {
    StatsCard
  },
  data() {
    return {
      type: ["", "info", "success", "warning", "danger"],
      notifications: {
        topCenter: false
      },
      card: {
        cpf: "",
        nomeCompleto: "",
        id: "",
        siape: "",
        papel: "",
        dataModificacao: ""
      },
      showCards: false,
      emailConfirmado: "",
      styleObjectEmail: {}
    };
  },
  methods: {
    ...mapActions("user", ["ActionGetUsuarioEntidadeById"]),
    ...mapActions("log_atividades", ["ActionPOSTLogAtividade"]),
    ...mapActions("auth", [
      "ActionSetIdPensionistaPerfil",
      "ActionSetIdServidorPerfil",
      "ActionSetIdUsuarioEntidade",
      "ActionPUTPerfilAtual",
      "ActionSetUserProfile",
      "ActionPOSTReenviarEmail"
    ]),
    async reEnviarEmail() {
      try {
        let papel = this.papel;
        let idUsuario = this.user_profile_state.idUsuario;
        await this.ActionPOSTReenviarEmail({
          papel,
          idUsuario
        }).then(res => {
          if (res.status === 200) {
            if (res.body.mensagem) {
              this.mensagem = res.body.mensagem;
              Swal.fire({
                //title: "Sucesso",
                icon: "success",
                text: "Email Enviado!",
                customClass: {
                  confirmButton: "md-button md-success btn-fill"
                },
                buttonsStyling: false
              });
            }
          }
        });
      } catch (err) {
        this.handleError(err);
      }
    },
    notifyVue(verticalAlign, horizontalAlign) {
      //console.log(this.user_state);
      if (
        this.user_profile_state == null ||
        this.user_profile_state == "" ||
        !this.user_profile_state.nomeusuario
      ) {
        //var color = Math.floor(Math.random() * 4 + 1);
        this.$notify({
          message:
            "Error ao carregar informações do USUÁRIO o sistema será redireciondo ao login.",
          icon: "add_alert",
          horizontalAlign: horizontalAlign,
          verticalAlign: verticalAlign,
          type: this.type[4],
          timeout: 10000
        });
        sleep(10000).then(() => {});
      }
    },
    chamarPensionistaView(id) {
      window._Vue.$router
        .push({
          name: "Pensionista Visualizar",
          params: { id: id }
        })
        .catch(err => {});
    },
    chamarServidorView(id) {
      window._Vue.$router
        .push({
          name: "Servidor Visualizar",
          params: { id: id }
        })
        .catch(err => {});
    },
    async selecionarEntidade(item) {
      this.user_profile_state.idPapel = item.idPapel;
      this.user_profile_state.idEntidade = item.idEntidade;
      this.user_profile_state.nomeEntidade = item.nomeEntidade;
      this.user_profile_state.papel = item.papel;
      let id = item.idUsuarioEntidade;
      let papel = this.user_profile_state.papel;

      if (item.papel === "PENSIONISTA" || item.papel === "SERVIDOR") {
        this.LogAtividade();
        await this.ActionGetUsuarioEntidadeById({ id, papel }).then(resp => {
          this.card.nomeCompleto = resp.data.nomeCompleto;
          this.card.papel = resp.data.papel;
          this.card.dataModificacao = resp.data.dataModificacao;
          this.ActionSetIdUsuarioEntidade(id);
          if (this.card.papel === "PENSIONISTA") {
            this.card.cpf = resp.data.cpfPensionista;
            this.card.id = resp.data.idPensionista;
            this.card.siape = resp.data.siapePensionista;
            this.ActionSetIdPensionistaPerfil(this.card.id);
          }
          if (this.card.papel === "SERVIDOR") {
            this.card.cpf = resp.data.cpfServidor;
            this.card.id = resp.data.idServidor;
            this.card.siape = resp.data.siapeServidor;
            this.ActionSetIdServidorPerfil(this.card.id);
          }
        });
      }
      let idUsuario = this.user_profile_state.idUsuario;
      let idEntidade = this.user_profile_state.idEntidade;
      let perfilAtual =
        item.papel +
        "|" +
        item.idUsuarioEntidade +
        "|" +
        item.idEntidade +
        "|" +
        this.card.id;

      let body = { perfilAtual, idEntidade };
      this.ActionPUTPerfilAtual({ idUsuario, papel, body });
      let copiedForm = Object.assign({}, this.user_profile_state);
      copiedForm.perfilAtual = perfilAtual;
      this.ActionSetUserProfile(copiedForm);
    },
    async loadCard() {
      if (this.idUsuarioEntidade !== "") {
        let id = this.idUsuarioEntidade;
        let papel = this.user_profile_state.papel;
        await this.ActionGetUsuarioEntidadeById({ id, papel }).then(resp => {
          this.card.nomeCompleto = resp.data.nomeCompleto;
          this.card.papel = resp.data.papel;
          this.card.dataModificacao = resp.data.dataModificacao;
          this.ActionSetIdUsuarioEntidade(id);
          if (this.card.papel === "PENSIONISTA") {
            this.card.cpf = resp.data.cpfPensionista;
            this.card.id = resp.data.idPensionista;
            this.card.siape = resp.data.siapePensionista;
            this.ActionSetIdPensionistaPerfil(this.card.id);
          } else if (this.card.papel === "SERVIDOR") {
            this.card.cpf = resp.data.cpfServidor;
            this.card.id = resp.data.idServidor;
            this.card.siape = resp.data.siapeServidor;
            this.ActionSetIdServidorPerfil(this.card.id);
          } else {
            this.card.cpf = resp.data.cpfServidor;
            this.card.id = resp.data.idServidor;
            this.card.siape = resp.data.siapeServidor;
            this.ActionSetIdServidorPerfil(this.card.id);
          }
        });
      }
    },
    async LogAtividade() {
      try {
        let id = this.user_profile_state.idUsuario;
        let payload = {
          id: id,
          nomeusuario: this.user_profile_state.nomeusuario,
          email: this.user_profile_state.email,
          rota: window._Vue.$route.path,
          destino: "PAGINA ENTRADA HOME(DASHBOARD)".toUpperCase(),
          atividade: "ACESSO".toUpperCase(),
          papel: this.user_profile_state.papel
        };
        await this.ActionPOSTLogAtividade(payload).then(resp => {});
      } catch (err) {
        this.handleError(err);
      }
    },
    handleError(err) {
      console.log(err);
      if (
        err === 400 ||
        err === 500 ||
        err === 404 ||
        err.status === 400 ||
        err.status === 500 ||
        err.status === 404
      ) {
        this.mensagem = "Aplicação temporariamente indisponível.";
      } else if (err.status === 401 || err.data === "Unauthorized") {
        this.mensagem = "Operação não Autorizada";
      } else {
        if (err.body) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 0) {
          this.mensagem =
            "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
        } else if (err.status === 409) {
          this.mensagem = err.body.mensagem;
        } else {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        }
      }
      Swal.fire({
        title: "Erro!",
        icon: "error",
        text: this.mensagem,
        customClass: {
          confirmButton: "md-button md-danger btn-fill"
        },
        buttonsStyling: false
      });
    }
  },
  computed: {
    ...mapState("auth", [
      "user_profile",
      "idPensionistaPerfil",
      "idServidorPerfil",
      "idUsuarioEntidade"
    ]),
    user_profile_state: state => state.user_profile,
    idPensionista: state => state.idPensionistaPerfil,
    idServidor: state => state.idServidorPerfil
    //idUsuarioEntidade: state => state.idUsuarioEntidade
    //loadCard2: function() {
    //  return this.loadCard();
    //}
  },
  beforeMount() {
    if (this.user_profile_state.perfilAtual !== null) {
      let arrayPerfilAtual = this.user_profile_state.perfilAtual.split("|");
      //console.log(arrayPerfilAtual);
      this.user_profile_state.papel = arrayPerfilAtual[0];
      let item = this.user_profile_state.usuarioEntidade.find(item => {
        if (item.idUsuarioEntidade === arrayPerfilAtual[1]) {
          return item;
        }
      });
      if (typeof item !== "undefined" && item !== null) {
        this.selecionarEntidade(item);
      } else {
        this.selecionarEntidade(this.user_profile_state.usuarioEntidade[0]);
      }
    } else {
      this.selecionarEntidade(this.user_profile_state.usuarioEntidade[0]);
    }
  },
  mounted: function() {
    this.notifyVue("top", "center");
    if (this.user_profile_state.termosCondicoes !== "1") {
      window._Vue.$router.push({ name: "Termos Condições" });
    }
    if (this.user_profile_state.emailConfirmado === "1") {
      this.showCards = true;
    }
    this.emailConfirmado =
      this.user_profile_state.emailConfirmado === "1"
        ? "Email Verificado"
        : "Email Não Verificado";

    this.styleObjectEmail =
      this.user_profile_state.emailConfirmado === "1"
        ? { color: "#49a54d", "font-weight": "bold" }
        : { color: "red", "font-weight": "bold" };
    //this.user_profile_state.usuarioEntidade.find();
    this.loadCard();
  }
  /*methods: {
    ...mapActions("auth", ["ActionLoadSession"]),
    async getUser() {
      try {
        await this.ActionLoadSession();
        console.log("err.status");
      } catch (err) {
        console.log(err.status);
        //alert(err.body.mensagem ? err.body.mensagem : err.body.mensagem);
      }
    }
  },
  beforeMount() {
    this.getUser();
  }*/
};
</script>

<style>
h5.title.h-title {
  font-size: 14px;
}

.md-card .md-card-content {
  padding: 0px 10px;
}

.md-card .category .md-icon {
  top: 0px;
}
</style>
