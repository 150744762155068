// =========================================================
// * Vue Material Dashboard PRO - v1.3.2
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import Vue from "vue";
// import VueRouter from "vue-router";
import DashboardPlugin from "./material-dashboard";
import VueVirtualScroller from "vue-virtual-scroller";

import TextHighlight from "vue-text-highlight";
// Plugins
import App from "./App.vue";
import router from "./router";
import Chartist from "chartist";

// router setup

import store from "./store";

// plugin setup
// Vue.use(VueRouter);
Vue.use(VueVirtualScroller);
Vue.use(DashboardPlugin);
Vue.component("text-highlight", TextHighlight);
Vue.config.productionTip = true;
//Vue.config.productionTip = false;

// configure router
/*const router = new VueRouter({
  routes, // short for routes: routes
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
  linkExactActiveClass: "nav-item active"
});*/

// global library setup
Vue.prototype.$Chartist = Chartist;

/* eslint-disable no-new */
window._Vue = new Vue({
  router,
  store,
  el: "#app",
  mounted() {
    //window.addEventListener("scroll", this.handleScroll);
    //document.addEventListener("touchstart", handler, { capture: true });
  },
  render: h => h(App)
});
