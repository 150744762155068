export default {
  getLogAtividadesPaginationAdmin: {
    method: "post",
    url: "api/v1/admin/logs/pagination"
  },
  getLogAtividadesByQueryAdmin: {
    method: "get",
    url: "api/v1/admin/logs/search/query{?q*}"
  },
  createLogAtividadesUser: {
    method: "post",
    url: "api/v1/me/logs{/id}"
  }
};
