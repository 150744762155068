export default {
  getArquivoByIdAdmin: {
    method: "get",
    url: "api/v1/admin/arquivo{/id}"
  },
  getArquivoAllAdmin: {
    method: "get",
    url: "api/v1/admin/arquivo/"
  },
  deleteArquivoByIdAdmin: {
    method: "delete",
    url: "api/v1/admin/arquivo{/id}"
  },
  updateArquivoAdmin: {
    method: "put",
    url: "api/v1/admin/arquivo{/id}"
  },
  createArquivoAdmin: {
    method: "post",
    url: "api/v1/admin/arquivo/"
  },
  getArquivoFromServAdmin: {
    method: "get",
    url: "api/v1/admin/arquivo/from_serv{/id}"
  },
  getArquivoFromPenAdmin: {
    method: "get",
    url: "api/v1/admin/arquivo/from_pen{/id}"
  },
  deletePastaByIdAdmin: {
    method: "delete",
    url: "api/v1/admin/arquivo/pasta{/id}"
  },
  updateNomePasta: {
    method: "put",
    url: "api/v1/admin/arquivo/pasta{/id}"
  },
  getArquivoVersoesByIdAdmin: {
    method: "get",
    url: "api/v1/admin/arquivo/versao{/id}"
  },
  getArquivoFromServUser: {
    method: "post",
    url: "api/v1/user/servidorpublico/arquivo/"
  },
  getArquivoFromPenUser: {
    method: "post",
    url: "api/v1/user/pensionista/arquivo/"
  },
  getArquivoFromServAdminEntidade: {
    method: "post",
    url: "api/v1/admin_entidade/servidorpublico/arquivo/"
  },
  getArquivoFromPenAdminEntidade: {
    method: "post",
    url: "api/v1/admin_entidade/pensionista/arquivo/"
  },
  getArquivoFromEntidadeByIdAdminEntidade: {
    method: "get",
    url: "api/v1/admin_entidade/arquivo/entidade{/id}"
  }
};
