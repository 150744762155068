import services from "@/http";
import store from "../../../store";

const namespaced = true;

export default {
  namespaced,
  state: {
    pensionista_quotaparte: {},
    message: null
  },
  actions: {
    ActionGetPensionistaQuotaParteById({ dispatch }, payload) {
      //console.log(payload);
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.pensionista_quotaparte.getPensionistaQuotaParteByIdAdmin(
              {
                id: id
              }
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetPensionistaQuotaPartePagination({ dispatch }, payload) {
      //console.log(payload);
      let id = payload.id;
      let papel = payload.papel;
      let noOfRecords = payload.noOfRecords;
      let pageIndex = payload.pageIndex;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.pensionista_quotaparte.getGetPensionistaQuotaPartePaginationAdmin(
              { id: id },
              { noOfRecords, pageIndex }
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionSetPENSIONISTA_QUOTAPARTE({ commit }, payload) {
      commit("SET_PENSIONISTA_QUOTAPARTE", payload);
    },
    ActionPUTPensionistaQuotaParte({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      let copiedForm = Object.assign({}, payload.form);

      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.pensionista_quotaparte.updatePensionistaQuotaParteAdmin(
              { id: id },
              copiedForm
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionPOSTPensionistaQuotaParte({ dispatch }, payload) {
      let papel = payload.papel;
      let copiedForm = Object.assign({}, payload.form);

      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.pensionista_quotaparte.createPensionistaQuotaParteAdmin(
              copiedForm
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionDELETEPensionistaQuotaParte({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.pensionista_quotaparte.deletePensionistaQuotaParteByIdAdmin(
              {
                id: id
              }
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetPensionistaQuotaPartesByQuery({ dispatch }, payload) {
      let papel = payload.papel;
      let id = payload.id;
      let anoMes = payload.anoMes;
      let fator = payload.fator;
      let noOfRecords = payload.noOfRecords;
      let pageIndex = payload.pageIndex;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.pensionista_quotaparte.getPensionistaQuotaPartesByQueryAdmin(
              {
                id,
                q: {
                  anoMes,
                  fator,
                  noOfRecords,
                  pageIndex
                }
              }
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err);
            return reject(err.status);
          }
        });
      }
    }
  },
  mutations: {
    SET_PENSIONISTA_QUOTAPARTE(state, payload) {
      state.pensionista_quotaparte = payload;
    }
  },
  getters: {
    pensionista_quotaparteState: state => {
      return state.pensionista_quotaparte;
    }
  }
};
