export const user = {
  path: "usuario",
  name: "Usuario Perfil",
  component: () => import(/* webpackChunkName: "user" */ "./pages/UserProfile")
};
export const edit_perfil = {
  path: "editar_perfil",
  name: "Editar Perfil",
  component: () =>
    import(/* webpackChunkName: "edit_user" */ "./pages/EditUserProfile")
};

export const change_password = {
  path: "change_password/:id",
  name: "Change User Password",
  props: true,
  component: () =>
    import(/* webpackChunkName: "change_password" */ "./pages/ChangePassword")
};

export const users = {
  path: "usuarios",
  name: "Usuarios",
  component: () =>
    import(/* webpackChunkName: "users_list" */ "./pages/UsuariosListAll")
};

export const novo_usuario = {
  path: "usuarios/novo_usuario",
  name: "Novo Usuario",
  component: () =>
    import(/* webpackChunkName: "novo_usuario" */ "./pages/NovoUsuario")
};

export const editar_usuario = {
  path: "usuarios/editar_usuario/:id",
  name: "Editar Usuario",
  props: true,
  component: () =>
    import(
      /* webpackChunkName: "usuarios/editar_usuario" */ "./pages/EditarUsuario"
    )
};

export default [
  user,
  edit_perfil,
  change_password,
  users,
  novo_usuario,
  editar_usuario
];
