<template>
  <div>
    <div style="padding-top:15px;display:inline-block;padding-left:10px;">
      <div class="md-layout">
        <div
          class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
        >
          <stats-card header-color="green">
            <template slot="header">
              <h4 class="title h-title">
                <p>
                  Termo de Uso :
                </p>
              </h4>
              <p class="category" v-bind:style="styleObjectTermo">
                Situação:
                {{ termosCondicoes }}
                <md-icon style="color: #49a54d;" v-if="checkTermosCondicoes()"
                  >check</md-icon
                ><md-icon style="color: red;" v-else>cancel</md-icon>
              </p>
            </template>
            <template slot="footer">
              <div class="stats">
                Arquivo para download :
                <a
                  style="padding: 0px 10px 0px 0px; font-weight: 400;"
                  href="/files/TermosCondicoesUso-Sindcalculos.pdf"
                  download
                  >Termo de Uso e Condições
                </a>
                <md-button
                  class="md-raised md-success mt-4"
                  @click="goToTermo()"
                >
                  Ler Termos e Condições
                </md-button>
              </div>
            </template>
          </stats-card>
        </div>
        <div
          class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-33"
        >
          <stats-card header-color="green">
            <template slot="header">
              <h4 class="title h-title">
                <p>
                  Verificação do Email :
                </p>
              </h4>
              <p class="category" v-bind:style="styleObjectEmail">
                Situação: {{ emailConfirmado
                }}<md-icon style="color: #49a54d;" v-if="checkEmailConfirmado()"
                  >check</md-icon
                ><md-icon style="color: red;" v-else>cancel</md-icon>
              </p>
              Para poder acessar o conteúdo do site é preciso verificar seu
              email através do email enviado.
            </template>
            <template slot="footer">
              <div class="stats">
                <md-button
                  class="md-raised md-success mt-4"
                  @click="reEnviarEmail()"
                >
                  Verificar Email
                </md-button>
              </div>
            </template>
          </stats-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { StatsCard } from "@/components";
import Swal from "sweetalert2";

export default {
  components: {
    StatsCard
  },
  data() {
    return {
      papel: "",
      isRed: true,
      isGreen: false,
      emailConfirmado: "",
      termosCondicoes: "",
      styleObjectTermo: {},
      styleObjectEmail: {}
    };
  },
  methods: {
    ...mapActions("user", ["ActionGetUsuarioEntidadeById"]),
    ...mapActions("auth", ["ActionPOSTReenviarEmail", "ActionSetUserProfile"]),
    getPapel() {
      this.papel = this.user_profile_state.papel;
    },
    checkTermosCondicoes() {
      if (this.termosCondicoes === "Aceito") {
        return true;
      } else {
        return false;
      }
    },
    goToTermo() {
      window._Vue.$router.push({ name: "Termos Condições" });
    },
    checkEmailConfirmado() {
      if (this.emailConfirmado === "Email Verificado") {
        return true;
      } else {
        return false;
      }
    },
    async reEnviarEmail() {
      try {
        let papel = this.papel;
        let idUsuario = this.user_profile_state.idUsuario;
        await this.ActionPOSTReenviarEmail({
          papel,
          idUsuario
        }).then(res => {
          if (res.status === 200) {
            if (res.body.mensagem) {
              this.mensagem = res.body.mensagem;
              Swal.fire({
                //title: "Sucesso",
                icon: "success",
                text: "Email Enviado!",
                customClass: {
                  confirmButton: "md-button md-success btn-fill"
                },
                buttonsStyling: false
              });
            }
          }
        });
      } catch (err) {
        this.handleError(err);
      }
    },
    handleError(err) {
      console.log(err);
      if (
        err === 400 ||
        err === 500 ||
        err === 404 ||
        err.status === 400 ||
        err.status === 500 ||
        err.status === 404
      ) {
        this.mensagem = "Aplicação temporariamente indisponível.";
      } else if (err.status === 401 || err.data === "Unauthorized") {
        this.mensagem = "Operação não Autorizada";
      } else {
        if (err.body) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 0) {
          this.mensagem =
            "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
        } else if (err.status === 409) {
          this.mensagem = err.body.mensagem;
        } else {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        }
      }
      Swal.fire({
        title: "Erro!",
        icon: "error",
        text: this.mensagem,
        customClass: {
          confirmButton: "md-button md-danger btn-fill"
        },
        buttonsStyling: false
      });
    }
  },
  computed: {
    ...mapState("auth", ["user_profile"]),
    user_profile_state: state => state.user_profile
  },
  beforeMount() {
    this.getPapel();
    this.emailConfirmado =
      this.user_profile_state.emailConfirmado === "1"
        ? "Email Verificado"
        : "Email Não Verificado";

    this.styleObjectEmail =
      this.user_profile_state.emailConfirmado === "1"
        ? { color: "#49a54d", "font-weight": "bold" }
        : { color: "red", "font-weight": "bold" };

    this.styleObjectTermo =
      this.user_profile_state.termosCondicoes === "1"
        ? { color: "#49a54d", "font-weight": "bold" }
        : { color: "red", "font-weight": "bold" };

    this.termosCondicoes =
      this.user_profile_state.termosCondicoes === "1" ? "Aceito" : "Não Aceito";
  }
};
</script>
<style>
.md-card .category .md-icon {
  top: 0px;
}
</style>
