export default {
  /*getFichaFinanceiraByIdAdmin: {
    method: "get",
    url: "api/v1/admin/importar{/id}"
  },
  getFichaFinanceiraAllAdmin: {
    method: "get",
    url: "api/v1/admin/importar/"
  },

  updateFichaFinanceiraAdmin: {
    method: "put",
    url: "api/v1/admin/importar{/id}"
  },
  createFichaFinanceiraAdmin: {
    method: "post",
    url: "api/v1/admin/importar/"
  },*/
  deleteFichaFinanceiraByIdAdmin: {
    method: "delete",
    url: "api/v1/admin/ficha_financeira{/id}"
  },
  getFichaFinanceiraWithSiape: {
    method: "post",
    url: "api/v1/admin/ficha_financeira/pagination"
  }
};
