import services from "@/http";
import store from "../../../store";

const namespaced = true;

export default {
  namespaced,
  state: {
    ficha_financeira: {},
    message: null
  },
  actions: {
    ActionSetFICHAFINANCEIRA({ commit }, payload) {
      commit("SET_FICHAFINANCEIRA", payload);
    },
    ActionDELETEFichaFinanceira({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.ficha_financeira.deleteFichaFinanceiraByIdAdmin(
              {
                id: id
              }
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetFichaFinanceiraWithSiapePagination({ dispatch }, payload) {
      //console.log(payload);
      //let id = payload.id;
      let papel = payload.papel;
      delete payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.ficha_financeira.getFichaFinanceiraWithSiape(
              payload
            );
            ////console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      }
    }
  },
  mutations: {
    SET_FICHAFINANCEIRA(state, payload) {
      state.ficha_financeira = payload;
    }
  },
  getters: {
    ficha_financeiraState: state => {
      return state.ficha_financeira;
    }
  }
};
