export default {
  getServidorPublicoByIdAdmin: {
    method: "get",
    url: "api/v1/admin/servidorpublico{/id}"
  },
  getServidoresPublicoAllAdmin: {
    method: "get",
    url: "api/v1/admin/servidorpublico/"
  },
  getServidorPublicoByEntidadeAdmin: {
    method: "get",
    url: "api/v1/admin/servidorpublico/from_entidade{/id}"
  },
  deleteServidorPublicoByIdAdmin: {
    method: "delete",
    url: "api/v1/admin/servidorpublico{/id}"
  },
  updateServidorPublicoAdmin: {
    method: "put",
    url: "api/v1/admin/servidorpublico{/id}"
  },
  createServidorPublicoAdmin: {
    method: "post",
    url: "api/v1/admin/servidorpublico/"
  },
  getServidoresPublicosPaginationAdmin: {
    method: "post",
    url: "api/v1/admin/servidorpublico/pagination"
  },
  getServidoresPublicosNomeColunasPaginationAdmin: {
    method: "post",
    url: "api/v1/admin/servidorpublico/nome_colunas"
  },
  getServidoresPublicosByQueryAdmin: {
    method: "get",
    url: "api/v1/admin/servidorpublico/search/query{?q*}"
  },
  getServidorPublicoByIdUser: {
    method: "post",
    url: "api/v1/user/servidorpublico/"
  },
  getServidoresPublicosPaginationAdminEntidade: {
    method: "post",
    url: "api/v1/admin_entidade/servidorpublico/pagination"
  },
  getServidoresPublicosByQueryAdminEntidade: {
    method: "get",
    url: "api/v1/admin_entidade/servidorpublico/search/query{?q*}"
  },
  getServidorPublicoByIdAdminEntidade: {
    method: "post",
    url: "api/v1/admin_entidade/servidorpublico/"
  },
  getServidorPublicoNomeColunasAdmin: {
    method: "get",
    url: "api/v1/admin/servidorpublico/nome_colunas/all"
  }
};
