import services from "@/http";
import store from "../../../store";

const namespaced = true;

export default {
  namespaced,
  state: {
    papelSelected: "",
    papeisSelected: []
  },
  actions: {
    ActionGetPapelById({ dispatch }, payload) {
      ////console.log(payload);
      let id = payload.id;
      let papel = payload.papel;

      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.papel.getPapelByIdAdmin({ id: id });
            ////console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "ADMIN_ENTIDADE") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.papel.getPapelByIdAdmin({ id: id });
            ////console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetPapelAll({ dispatch }, payload) {
      //console.log(payload);
      //let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.papel.getPapelAllAdmin();
            ////console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "ADMIN_ENTIDADE") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.papel.getPapelAllAdmin();
            //console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionSetPapel({ commit }, payload) {
      commit("SET_PAPEL", payload);
    },
    ActionSetPapelAll({ commit }, payload) {
      commit("SET_PAPEIS_ALL", payload);
    },
    ActionPUTPapel({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      //console.log(payload.newForm);
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.papel.updatePapelAdmin(
              { id: id },
              payload.newForm
            );
            ////console.log(response);
            resolve(response);
          } catch (err) {
            ////console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionPOSTPapel({ dispatch }, payload) {
      let papel = payload.papel;
      //console.log(payload);
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.papel.createPapelAdmin(payload.form);
            //console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionDELETEPapel({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      //console.log(payload);
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.papel.deletePapelByIdAdmin(
              { id: id },
              payload.form
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      }
    }
  },
  mutations: {
    SET_PAPEL(state, payload) {
      state.papelSelected = payload;
    },
    SET_PAPEIS_ALL(state, payload) {
      state.papeisSelected = payload;
    }
  },
  getters: {
    papelSelectedState: state => {
      return state.papelSelected;
    }
  }
};
