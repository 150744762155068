import services from "@/http";
import store from "../../../store";

const namespaced = true;

export default {
  namespaced,
  state: {
    servidorOrPensionistaSelected: {},
    servidoresFromPensionista: [],
    servidorPensionista: {},
    servidoresPensionistas: []
  },
  actions: {
    ActionGetServidorPensionistaById({ dispatch }, payload) {
      //console.log(payload);
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.servidor_pensionista.getServidorPensionistaByIdAdmin(
              { id: id }
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "ADMIN_ENTIDADE") {
        return new Promise(async (resolve, reject) => {
          try {
            let idEntidade = payload.idEntidade;
            let response = services.servidor_pensionista.getServidorPensionistaByIdAdminEntidade(
              { id: id, idEntidade: idEntidade }
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionPOSTServidorPensionista({ dispatch }, payload) {
      let papel = payload.papel;
      let copiedForm = Object.assign({}, payload.servidorPensionista);
      delete copiedForm.nomeEntidade;
      delete copiedForm.idEntidade;
      let i = 0;
      while (i < copiedForm.siapePensionista.length) {
        copiedForm.siapePensionista = copiedForm.siapePensionista.replace(
          /^(?![1-9])0/g,
          ""
        );
        i++;
      }
      Object.keys(copiedForm).map(key => {
        return (copiedForm[key] =
          copiedForm[key] === null
            ? null
            : copiedForm[key].toString().toUpperCase());
      });

      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.servidor_pensionista.createServidorPensionistaAdmin(
              copiedForm
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionPUTServidorPensionista({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      let copiedForm = Object.assign({}, payload.servidorPensionista);
      delete copiedForm.nomeEntidade;
      delete copiedForm.idEntidade;

      let i = 0;
      while (i < copiedForm.siapePensionista.length) {
        copiedForm.siapePensionista = copiedForm.siapePensionista.replace(
          /^(?![1-9])0/g,
          ""
        );
        i++;
      }

      Object.keys(copiedForm).map(key => {
        return (copiedForm[key] =
          copiedForm[key] === null
            ? null
            : copiedForm[key].toString().toUpperCase());
      });

      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.servidor_pensionista.updateServidorPensionistaAdmin(
              { id: id },
              copiedForm
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetServidorOrPensionistaToAssingmentFromEntidadeAll(
      { dispatch },
      payload
    ) {
      //console.log(payload);
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.servidor_pensionista.getServidorPensionistaToAssingmentFromEntidadeAdmin(
              payload.body
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "ADMIN_ENTIDADE") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.servidor_pensionista.getServidorPensionistaToAssingmentFromEntidadeAdminEntidade(
              payload.body
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetServidorFromPen({ dispatch }, payload) {
      //console.log(payload);
      let id = payload.id;
      let papel = payload.papel;

      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.servidor_pensionista.getServidorFromPenAdmin(
              { id: id }
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "PENSIONISTA") {
        let idEntidade = payload.idEntidade;
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.servidor_pensionista.getServidorFromPenUser(
              { id: id, idEntidade: idEntidade }
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "ADMIN_ENTIDADE") {
        let idEntidade = payload.idEntidade;
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.servidor_pensionista.getServidorFromPenAdminEntidade(
              { id: id, idEntidade: idEntidade }
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionSetServidorOrPensionistaSelected({ commit }, payload) {
      commit("SET_SERVIDOR_PENSIONISTA_SELECTED", payload);
    },
    ActionSetServidoresFromPensionista({ commit }, payload) {
      commit("SET_SERVIDORES_FROM_PENSIONISTA", payload);
    },
    ActionSetServidorPensionista({ commit }, payload) {
      commit("SET_SERVIDOR_PENSIONISTA", payload);
    },
    ActionSetServidoresPensionistas({ commit }, payload) {
      commit("SET_SERVIDORES_PENSIONISTAS", payload);
    },
    ActionDELETEServidorPensionistaById({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;

      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.servidor_pensionista.deleteServidorPensionistaByIdAdmin(
              { id: id }
            );
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    }
  },
  mutations: {
    SET_SERVIDOR_PENSIONISTA_SELECTED(state, payload) {
      state.servidorOrPensionistaSelected = payload;
    },
    SET_SERVIDORES_FROM_PENSIONISTA(state, payload) {
      state.servidoresFromPensionista = payload;
    },
    SET_SERVIDOR_PENSIONISTA(state, payload) {
      state.servidorPensionista = payload;
    },
    SET_SERVIDORES_PENSIONISTAS(state, payload) {
      state.servidoresPensionistas = payload;
    }
  },
  getters: {
    getServidorOrPensionistaSelectedState: state => {
      return state.servidorOrPensionistaSelected;
    },
    getServidoresFromPensionistaState: state => {
      let dups = [];
      let arr = state.servidoresFromPensionista.filter(function(el) {
        // If it is not a duplicate, return true
        if (dups.indexOf(el.idServidor) == -1) {
          dups.push(el.idServidor);
          return true;
        }
        return false;
      });
      return arr;
    },
    getServidorPensionistaState: state => {
      return state.servidorPensionista;
    },
    getServidoresPensionistasState: state => {
      return state.servidoresPensionistas;
    }
  }
};
