export default {
  getPapelByIdAdmin: {
    method: "get",
    url: "api/v1/admin/papel{/id}"
  },
  getPapelAllAdmin: {
    method: "get",
    url: "api/v1/admin/papel/"
  },
  deletePapelByIdAdmin: {
    method: "delete",
    url: "api/v1/admin/papel{/id}"
  },
  updatePapelAdmin: {
    method: "put",
    url: "api/v1/admin/papel{/id}"
  },
  createPapelAdmin: {
    method: "post",
    url: "api/v1/admin/papel/"
  }
};
