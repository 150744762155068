import services from "@/http";
import store from "../../../store";

const namespaced = true;

export default {
  namespaced,
  state: {
    pensionista: {},
    message: null
  },
  actions: {
    ActionGetPensionistaById({ dispatch }, payload) {
      //console.log(payload);
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.pensionista.getPensionistaByIdAdmin({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "PENSIONISTA") {
        return new Promise(async (resolve, reject) => {
          let idEntidade = payload.idEntidade;
          try {
            let response = services.pensionista.getPensionistaByIdUser({
              id: id,
              idEntidade: idEntidade
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "ADMIN_ENTIDADE") {
        return new Promise(async (resolve, reject) => {
          let idEntidade = payload.idEntidade;
          try {
            let response = services.pensionista.getPensionistaByIdAdminEntidade(
              {
                id: id,
                idEntidade: idEntidade
              }
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetPensionistaAll({ dispatch }, payload) {
      //console.log(payload);
      //let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.pensionista.getPensionistaAllAdmin();
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetPensionistasPagination({ dispatch }, payload) {
      let papel = payload.papel;
      delete payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            delete payload.idEntidade;
            let payloadCopied = payload;
            let response = services.pensionista.getPensionistasPaginationAdmin(
              payloadCopied
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "ADMIN_ENTIDADE") {
        return new Promise(async (resolve, reject) => {
          try {
            let payloadCopied = payload;
            let response = services.pensionista.getPensionistasPaginationAdminEntidade(
              payloadCopied
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionSetPENSIONISTA({ commit }, payload) {
      commit("SET_PENSIONISTA", payload);
    },
    ActionPUTPensionista({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      let copiedForm = Object.assign({}, payload.form);
      delete copiedForm.nomeEntidade;
      delete copiedForm.mesAniversario;
      delete copiedForm.diaAniversario;

      copiedForm.cpfPensionista =
        copiedForm.cpfPensionista === null
          ? null
          : Number(
              copiedForm.cpfPensionista
                .toString()
                .replace(/\./g, "")
                .replace(/-/g, "")
            );

      Object.keys(copiedForm).map(key => {
        return (copiedForm[key] =
          copiedForm[key] === null
            ? null
            : copiedForm[key].toString().toUpperCase());
      });

      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.pensionista.updatePensionistaAdmin(
              { id: id },
              copiedForm
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionPOSTPensionista({ dispatch }, payload) {
      let papel = payload.papel;
      let copiedForm = Object.assign({}, payload.form);
      delete copiedForm.nomeEntidade;
      delete copiedForm.mesAniversario;
      delete copiedForm.diaAniversario;
      delete copiedForm.idPensionista;
      copiedForm.cpfPensionista =
        copiedForm.cpfPensionista === null
          ? null
          : Number(
              copiedForm.cpfPensionista
                .toString()
                .replace(/\./g, "")
                .replace(/-/g, "")
            );

      Object.keys(copiedForm).map(key => {
        return (copiedForm[key] =
          copiedForm[key] === null
            ? null
            : copiedForm[key].toString().toUpperCase());
      });

      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.pensionista.createPensionistaAdmin(
              copiedForm
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionDELETEPensionista({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.pensionista.deletePensionistaByIdAdmin({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetPensionistasByQuery({ dispatch }, payload) {
      //console.log(payload);
      let papel = payload.papel;
      let nome_completo = payload.nomeCompleto;
      let cpf = payload.cpfPensionista;
      let upag_descricao = payload.upagDescricao;
      let siape_pensionista = payload.siapePensionista;
      let id_entidade = payload.idEntidade;
      let noOfRecords = payload.noOfRecords;
      let pageIndex = payload.pageIndex;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.pensionista.getPensionistasByQueryAdmin({
              q: {
                nome_completo,
                cpf,
                upag_descricao,
                siape_pensionista,
                id_entidade,
                noOfRecords,
                pageIndex
              }
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err);
            return reject(err.status);
          }
        });
      } else if (papel === "ADMIN_ENTIDADE") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.pensionista.getPensionistasByQueryAdminEntidade(
              {
                q: {
                  nome_completo,
                  cpf,
                  upag_descricao,
                  siape_pensionista,
                  id_entidade,
                  noOfRecords,
                  pageIndex
                }
              }
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err);
            return reject(err.status);
          }
        });
      }
    },
    async ActionSubmitPensionistaAllData({ dispatch }, payload) {
      let papel = payload.papel;
      let responses = {};
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            if (
              this.state.pensionista.pensionista !== null &&
              this.state.pensionista.pensionista !== ""
            ) {
              let form = this.state.pensionista.pensionista;
              console.log("CALL ActionSubmitPensionistaAllData");
              let response = dispatch("ActionPOSTPensionista", {
                papel,
                form
              })
                .then(res => {
                  return new Promise(async (resolve, reject) => {
                    let idPensionista = null;

                    responses = { responsePensionista: res };
                    if (res.status === 200) {
                      if (res.body.mensagem) {
                        this.mensagem = res.body.mensagem;
                        if (this.mensagem.includes("não")) {
                          return res;
                        }
                      }
                      idPensionista = res.body.idPensionista;
                      if (
                        this.state.servidor_pensionista
                          .servidoresPensionistas !== [] &&
                        this.state.servidor_pensionista
                          .servidoresPensionistas !== null &&
                        this.state.servidor_pensionista
                          .servidoresPensionistas !== "" &&
                        typeof this.state.servidor_pensionista
                          .servidoresPensionistas !== "undefined" &&
                        this.state.servidor_pensionista.servidoresPensionistas
                          .length > 0
                      ) {
                        this.state.servidor_pensionista.servidoresPensionistas.map(
                          servPen => {
                            Object.keys(servPen).map(key => {
                              if (key === "idEntidade") {
                                servPen[
                                  key
                                ] = this.state.pensionista.pensionista.idEntidade;
                              }
                              return servPen[key];
                            });
                          }
                        );
                        let responseServidorPensionista = [];
                        this.state.servidor_pensionista.servidoresPensionistas.map(
                          servPen => {
                            if (
                              servPen.siapePensionista !== "" &&
                              servPen.servidorPublico !== ""
                            ) {
                              console.log("CALL Servidor Pensionista");
                              servPen = {
                                ...servPen,
                                pensionista: idPensionista
                              };
                              //console.log(servPen);
                              let servidorPensionista = servPen;
                              let i = 0;
                              while (
                                i < servidorPensionista.siapePensionista.length
                              ) {
                                servidorPensionista.siapePensionista = servidorPensionista.siapePensionista.replace(
                                  /^(?![1-9])0/g,
                                  ""
                                );
                                i++;
                              }

                              let responseServPen = dispatch(
                                "servidor_pensionista/ActionPOSTServidorPensionista",
                                {
                                  papel,
                                  servidorPensionista
                                },
                                { root: true }
                              )
                                .then(res => {
                                  responseServidorPensionista.push(res);
                                })
                                .catch(err => {
                                  responseServidorPensionista.push(err);
                                });
                            }
                          }
                        );
                        //console.log(responseServidorPensionista);
                        responses = {
                          ...responses,
                          responseServidorPensionista: responseServidorPensionista
                        };
                      }
                      if (
                        this.state.endereco.endereco !== null &&
                        this.state.endereco.endereco !== "" &&
                        typeof this.state.endereco.endereco.length !==
                          "undefined"
                      ) {
                        let responseEndereco = [];
                        this.state.endereco.endereco.map(end => {
                          if (end.logradouro !== "") {
                            console.log("CALL Endereco");
                            let endereco = end;
                            endereco = {
                              ...endereco,
                              pensionista: idPensionista
                            };
                            let responseEnd = dispatch(
                              "endereco/ActionPOSTEndereco",
                              {
                                papel,
                                endereco
                              },
                              { root: true }
                            )
                              .then(res => {
                                responseEndereco.push(res);
                              })
                              .catch(err => {
                                responseEndereco.push(err);
                              });
                          }
                        });
                        console.log(responseEndereco);
                        responses = {
                          ...responses,
                          responseEndereco: responseEndereco
                        };
                      }
                      if (
                        this.state.telefone.telefone !== null &&
                        this.state.telefone.telefone !== "" &&
                        typeof this.state.telefone.telefone.length !==
                          "undefined"
                      ) {
                        let responseTelefone = [];
                        this.state.telefone.telefone.map(tel => {
                          if (tel.numero !== "") {
                            console.log("CALL Telefone");
                            let telefone = tel;
                            telefone = {
                              ...telefone,
                              pensionista: idPensionista
                            };
                            let responseTel = dispatch(
                              "telefone/ActionPOSTTelefone",
                              {
                                papel,
                                telefone
                              },
                              { root: true }
                            )
                              .then(res => {
                                responseTelefone.push(res);
                              })
                              .catch(err => {
                                responseTelefone.push(err);
                              });
                          }
                        });
                        console.log(responseTelefone);
                        responses = {
                          ...responses,
                          responseTelefone: responseTelefone
                        };
                      }
                      if (
                        this.state.email.email !== null &&
                        this.state.email.email !== "" &&
                        typeof this.state.email.email.length !== "undefined"
                      ) {
                        let responseEmail = [];
                        this.state.email.email.map(e => {
                          if (e.mail !== "") {
                            console.log("CALL Email");
                            let email = e;
                            email = {
                              ...email,
                              pensionista: idPensionista
                            };
                            let responseE = dispatch(
                              "email/ActionPOSTEmail",
                              {
                                papel,
                                email
                              },
                              { root: true }
                            )
                              .then(res => {
                                responseEmail.push(res);
                              })
                              .catch(err => {
                                responseEmail.push(err);
                              });
                          }
                        });
                        console.log(responseEmail);
                        responses = {
                          ...responses,
                          responseEmail: responseEmail
                        };
                      }
                      resolve(responses);
                    } else {
                      reject(responses);
                    }
                  });
                })
                .catch(err => {
                  responses = { servidorPensionista: err };
                  return responses;
                });
              resolve(response);
            } else {
              return reject(
                "não foi possivel ler os dados do Pensionista no cadastro."
              );
            }
          } catch (err) {
            console.log(err);
            return reject(err);
          }
        });
      }
    }
  },
  mutations: {
    SET_PENSIONISTA(state, payload) {
      state.pensionista = payload;
    }
  },
  getters: {
    pensionistaState: state => {
      return state.pensionista;
    }
  }
};
