export const pensionista_quotapartes = {
  name: "Quota Partes",
  path: "/pensionistas/detalhe_pensionista/:id/quotapartes",
  props: true,
  component: () =>
    import(
      /* webpackChunkName: "detalhe_pensionista/:id/quotapartes" */ "./pages/QuotaPartes"
    )
};

export const novo_pensionista_quotapartes = {
  name: "Nova Quota Partes",
  path: "/pensionistas/quotapartes",
  props: true,
  component: () =>
    import(
      /* webpackChunkName: "detalhe_pensionista/:id/quotapartes" */ "./pages/QuotaPartes"
    )
};

export default [pensionista_quotapartes, novo_pensionista_quotapartes];
