<template>
  <div class="full-page" :class="{ 'nav-open': $sidebar.showSidebar }">
    <md-toolbar md-elevation="0" class="md-transparent md-toolbar-absolute">
      <div class="md-toolbar-row md-offset">
        <div class="md-toolbar-section-start">
          <h3 class="md-title">{{ $route.name }}</h3>
        </div>
        <div class="md-toolbar-section-end">
          <md-button
            class="md-just-icon md-simple md-round md-toolbar-toggle"
            :class="{ toggled: $sidebar.showSidebar }"
            @click="toggleSidebar"
          >
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </md-button>

          <div
            class="md-collapse"
            :class="{ 'off-canvas-sidebar': responsive }"
          >
            <md-list>
              <md-list-item href="#" @click="linkClick('SUPORTE')">
                <md-icon>contact_support</md-icon>
                Suporte
              </md-list-item>
              <md-list-item href="#" @click="linkClick('SOBRE')">
                <md-icon>store</md-icon>
                Sobre nós
              </md-list-item>
              <md-list-item href="#" @click="linkClick('CONTATO')">
                <md-icon>contact_mail</md-icon>
                Contato
              </md-list-item>
              <md-list-item href="#/login" @click="linkClick">
                <md-icon>fingerprint</md-icon>
                login
              </md-list-item>
              <md-list-item href="#/registro" @click="linkClick">
                <md-icon>person_add_alt_1 </md-icon>
                registro
              </md-list-item>
              <!--<md-list-item href="#/lock" @click="linkClick">
                <md-icon>lock_open</md-icon>
                lock
              </md-list-item>-->
            </md-list>
          </div>
        </div>
      </div>
    </md-toolbar>
    <div class="wrapper wrapper-full-page" @click="toggleSidebarPage">
      <div
        class="page-header header-filter"
        :class="setPageClass"
        filter-color="black"
      >
        <div class="container md-offset">
          <zoom-center-transition
            :duration="pageTransitionDuration"
            mode="out-in"
          >
            <router-view></router-view>
          </zoom-center-transition>
        </div>
        <footer class="footer">
          <div class="container md-offset">
            <nav>
              <ul>
                <li>
                  <router-link :to="{ path: '/dashboard' }">Home</router-link>
                </li>
                <!--<li>
                  <a href="#">
                    Company
                  </a>
                </li>
                <li>
                  <a href="#">
                    Portfolio
                  </a>
                </li>
                <li>
                  <a href="#">
                    Blog
                  </a>
                </li> -->
              </ul>
            </nav>
            <div class="copyright text-center">
              &copy; {{ new Date().getFullYear() }}
              <a href="https://www.sindcalculos.com.br" target="_blank"
                >SindCalculo</a
              >, made with <i class="material-icons">computer</i> for a better
              web
            </div>
          </div>
        </footer>
      </div>
    </div>
    <template>
      <modal v-if="classicModal" @close="classicModalHide">
        <template slot="header">
          <h4 class="modal-title">{{ modalTitle }}</h4>
          <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="classicModalHide"
          >
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="body">
          <div class="md-layout">
            <div class="md-layout-item md-size-100 text-right">
              <p><span v-html="mensagemHtml"></span></p>
            </div>
          </div>
        </template>
        <template slot="footer"> </template>
      </modal>
    </template>
  </div>
</template>
<script>
import { ZoomCenterTransition } from "vue2-transitions";
import { Modal } from "@/components";

export default {
  components: {
    ZoomCenterTransition,
    Modal
  },
  props: {
    backgroundColor: {
      type: String,
      default: "black"
    }
  },
  inject: {
    autoClose: {
      default: true
    }
  },
  data() {
    return {
      responsive: false,
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 300,
      year: new Date().getFullYear(),
      classicModal: false,
      modalTitle: "",
      mensagemHtml: ""
    };
  },
  computed: {
    setBgImage() {
      let images = {
        Pricing: "./img/bg-pricing.jpg",
        Login: "./img/login.jpg",
        Register: "./img/register.jpg",
        Lock: "./img/lock.jpg"
      };
      return {
        backgroundImage: `url(${images[this.$route.name]})`
      };
    },
    setPageClass() {
      return `${this.$route.name}-page`.toLowerCase();
    }
  },
  methods: {
    classicModalHide: function() {
      this.classicModal = false;
    },
    toggleSidebarPage() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    linkClick(value) {
      //console.log("CLICL");
      if (value === "CONTATO") {
        this.classicModal = true;
        this.modalTitle = "Contato";
        this.mensagemHtml = `<p></p>
<h4 style="text-align: center;"><strong>Contato Comercial:</strong></h4>
<h4 style="text-align: center;"><strong>de 2&ordf; as 6&ordf; das 9h as 17h</strong></h4>
<p style="text-align: center;">&nbsp;Pe&ccedil;a um cota&ccedil;&atilde;o de nosso servi&ccedil;os de Calculos.</p>
<h4 style="text-align: center;"><span style="color: #339966;"><strong>e-mail :&nbsp; sindcalculos@gmail.com</strong></span></h4>
<p style="text-align: center;"></p>`;
      } else if (value === "SOBRE") {
        this.classicModal = true;
        this.modalTitle = "Sobre nós";
        this.mensagemHtml = `<h4 style="text-align: center;"></h4>
<p style="text-align: center;">&nbsp;Somos uma empresa focada em desenvolver c&aacute;lculos avan&ccedil;ados para processos de servidores p&uacute;blicos e ou pensionistas.</p>
<p style="text-align: center;"></p>`;
      } else if (value === "SUPORTE") {
        this.classicModal = true;
        this.modalTitle = "Suporte";
        this.mensagemHtml = `<p></p>
<h4 style="text-align: center;"><strong>Nosso Suporte Funciona :</strong></h4>
<h4 style="text-align: center;"><strong>de 2&ordf; as 6&ordf; das 9h as 17h</strong></h4>
<p style="text-align: center;">Nosso tempo m&eacute;dio de resposta &eacute; 4 horas a partir do recebimento.</p>
<p style="text-align: center;">Para contatar o suporte envie um email com seu usu&aacute;rio ou email de login uma breve descri&ccedil;&atilde;o do problema e um imagem da tela contendo o erro.&nbsp;</p>
<h4 style="text-align: center;"><span style="color: #339966;"><strong>e-mail :&nbsp;suporte.sindcalculos@gmail.com</strong></span></h4>
<p style="text-align: center;"></p>`;
      }
      if (
        this.autoClose &&
        this.$sidebar &&
        this.$sidebar.showSidebar === true
      ) {
        this.$sidebar.displaySidebar(false);
      }
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      this.showMenu = false;
    },
    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    }
  },
  mounted() {
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  },
  beforeDestroy() {
    this.closeMenu();
    window.removeEventListener("resize", this.onResponsiveInverted);
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  }
};
</script>
<style lang="scss" scoped>
$scaleSize: 0.1;
$zoomOutStart: 0.7;
$zoomOutEnd: 0.46;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}
.wrapper-full-page .zoomIn {
  animation-name: zoomIn8;
}
@keyframes zoomOut8 {
  from {
    opacity: 1;
    transform: scale3d($zoomOutStart, $zoomOutStart, $zoomOutStart);
  }
  to {
    opacity: 0;
    transform: scale3d($zoomOutEnd, $zoomOutEnd, $zoomOutEnd);
  }
}
.wrapper-full-page .zoomOut {
  animation-name: zoomOut8;
}
</style>
