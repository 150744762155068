import * as types from "./mutation-types";

export default {
  [types.SET_USER_PROFILE](state, payload) {
    state.user_profile = payload;
  },
  [types.SET_TOKEN](state, payload) {
    state.jwt = payload;
  },
  [types.SET_ID_PENSIONISTA_PERFIL](state, payload) {
    state.idPensionistaPerfil = payload;
  },
  [types.SET_ID_SERVIDOR_PERFIL](state, payload) {
    state.idServidorPerfil = payload;
  },
  [types.SET_ID_USUARIO_ENTIDADE](state, payload) {
    state.idUsuarioEntidade = payload;
  }
};
