import services from "@/http";
import store from "../../../store";

const namespaced = true;

export default {
  namespaced,
  state: {
    email: {},
    message: null
  },
  actions: {
    ActionGetEmailById({ dispatch }, payload) {
      //console.log(payload);
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.email.getEmailByIdAdmin({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.error(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetEmailAll({ dispatch }, payload) {
      //console.log(payload);
      //let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.email.getServidoresPublicoAllAdmin();
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.error(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionSetEMAIL({ commit }, payload) {
      commit("SET_EMAIL", payload);
    },
    ActionPUTEmail({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      let copiedForm = Object.assign({}, payload.email);

      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.email.updateEmailAdmin(
              { id: id },
              copiedForm
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.error(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionPOSTEmail({ dispatch }, payload) {
      let papel = payload.papel;
      let copiedForm = Object.assign({}, payload.email);

      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.email.createEmailAdmin(copiedForm);
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.error(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetEmailFromServ({ dispatch }, payload) {
      //console.log(payload);
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.email.getEmailFromServAdmin({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.error(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "ADMIN_ENTIDADE") {
        return new Promise(async (resolve, reject) => {
          try {
            let idEntidade = payload.idEntidade;
            let response = services.email.getEmailFromServAdminEntidade({
              id: id,
              idEntidade: idEntidade
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.error(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetEmailFromPen({ dispatch }, payload) {
      //console.log(payload);
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.email.getEmailFromPenAdmin({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.error(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "ADMIN_ENTIDADE") {
        return new Promise(async (resolve, reject) => {
          try {
            let idEntidade = payload.idEntidade;
            let response = services.email.getEmailFromPenAdminEntidade({
              id: id,
              idEntidade: idEntidade
            });
            console.log(response);
            resolve(response);
          } catch (err) {
            console.error(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionDELETEEmail({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.email.deleteEmailByIdAdmin({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.error(err.status);
            return reject(err.status);
          }
        });
      }
    }
  },
  mutations: {
    SET_EMAIL(state, payload) {
      state.email = payload;
    }
  },
  getters: {
    emailState: state => {
      return state.email;
    }
  }
};
