import services from "@/http";
import store from "../../../store";

const namespaced = true;

export default {
  namespaced,
  state: {
    entidade: {},
    entidades: []
  },
  actions: {
    ActionGetEntidadeById({ dispatch }, payload) {
      //console.log(payload);
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.entidade.getEntidadeByIdAdmin({ id: id });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "ADMIN_ENTIDADE") {
        //console.log(payload);
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.entidade.getEntidadeByIdAdminEntidade({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetEntidadeAll({ dispatch }, payload) {
      //let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        delete payload.idEntidade;
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.entidade.getEntidadeAllAdmin();
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "ADMIN_ENTIDADE") {
        let id = payload.idEntidade;
        //console.log(payload);
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.entidade.getEntidadeAllAdminEntidade({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionSetEntidade({ commit }, payload) {
      commit("SET_ENTIDADE", payload);
    },
    ActionSetEntidadeAll({ commit }, payload) {
      commit("SET_ENTIDADE_ALL", payload);
    },
    ActionPUTEntidade({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      //console.log(payload.newForm);
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.entidade.updateEntidadeAdmin(
              { id: id },
              payload.newForm
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionPOSTEntidade({ dispatch }, payload) {
      let papel = payload.papel;
      //console.log(payload);
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.entidade.createEntidadeAdmin(payload.form);
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionDELETEEntidade({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      //console.log(payload);
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.entidade.deleteEntidadeByIdAdmin(
              { id: id },
              payload.form
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    }
  },
  mutations: {
    SET_ENTIDADE(state, payload) {
      state.entidade = payload;
    },
    SET_ENTIDADE_ALL(state, payload) {
      state.entidades = payload;
    }
  },
  getters: {
    success(state, payload) {
      state.entidade = payload;
    }
  }
};
