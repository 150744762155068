import services from "@/http";
import store from "../../../store";

const namespaced = true;

export default {
  namespaced,
  state: {
    servidor_publico: {},
    message: null
  },
  actions: {
    ActionGetServidorPublicoById({ dispatch }, payload) {
      //console.log(payload);
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.servidor_publico.getServidorPublicoByIdAdmin(
              { id: id }
            );
            ////console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "SERVIDOR") {
        return new Promise(async (resolve, reject) => {
          let idEntidade = payload.idEntidade;
          try {
            let response = services.servidor_publico.getServidorPublicoByIdUser(
              {
                id: id,
                idEntidade: idEntidade
              }
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "ADMIN_ENTIDADE") {
        return new Promise(async (resolve, reject) => {
          let idEntidade = payload.idEntidade;
          try {
            let response = services.servidor_publico.getServidorPublicoByIdAdminEntidade(
              {
                id: id,
                idEntidade: idEntidade
              }
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetServidorPublicoAll({ dispatch }, payload) {
      ////console.log(payload);
      //let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.servidor_publico.getServidoresPublicoAllAdmin();
            ////console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetServidorPublicoNomeColunasAll({ dispatch }, payload) {
      //console.log(payload);
      //let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.servidor_publico.getServidorPublicoNomeColunasAdmin();
            ////console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetServidorPublicoByEntidade({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.servidor_publico.getServidorPublicoByEntidadeAdmin(
              { id: id }
            );
            ////console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetServidorPublicoPagination({ dispatch }, payload) {
      //console.log(payload);
      //let id = payload.id;
      let papel = payload.papel;
      delete payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            delete payload.idEntidade;
            let payloadCopied = payload;
            let response = services.servidor_publico.getServidoresPublicosPaginationAdmin(
              payloadCopied
            );
            ////console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "ADMIN_ENTIDADE") {
        return new Promise(async (resolve, reject) => {
          try {
            let payloadCopied = payload;
            let response = services.servidor_publico.getServidoresPublicosPaginationAdminEntidade(
              payloadCopied
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetServidorPublicoNomeColunasPagination({ dispatch }, payload) {
      //console.log(payload);
      //let id = payload.id;
      let papel = payload.papel;
      delete payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.servidor_publico.getServidoresPublicosNomeColunasPaginationAdmin(
              payload
            );
            ////console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetServidorPublicoByQuery({ dispatch }, payload) {
      ////console.log(payload);
      let papel = payload.papel;
      let nome_completo = payload.nomeCompleto;
      let cpf = payload.cpfServidor;
      let org_descricao = payload.orgDescricao;
      let siape_servidor = payload.siapeServidor;
      let situacao = payload.situacao;
      let id_entidade = payload.idEntidade;
      let noOfRecords = payload.noOfRecords;
      let pageIndex = payload.pageIndex;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.servidor_publico.getServidoresPublicosByQueryAdmin(
              {
                q: {
                  nome_completo,
                  cpf,
                  org_descricao,
                  siape_servidor,
                  situacao,
                  id_entidade,
                  noOfRecords,
                  pageIndex
                }
              }
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err);
            return reject(err.status);
          }
        });
      } else if (papel === "ADMIN_ENTIDADE") {
        return new Promise(async (resolve, reject) => {
          try {
            //console.log(payload);
            let response = services.servidor_publico.getServidoresPublicosByQueryAdminEntidade(
              {
                q: {
                  nome_completo,
                  cpf,
                  org_descricao,
                  siape_servidor,
                  situacao,
                  id_entidade,
                  noOfRecords,
                  pageIndex
                }
              }
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err);
            return reject(err.status);
          }
        });
      }
    },
    ActionSetSERVIDOR_PUBLICO({ commit }, payload) {
      commit("SET_SERVIDOR_PUBLICO", payload);
    },
    ActionPUTServidorPublico({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      let copiedForm = Object.assign({}, payload.form);
      delete copiedForm.nomeEntidade;

      let i = 0;
      while (i < copiedForm.siapeServidor.length) {
        copiedForm.siapeServidor = copiedForm.siapeServidor.replace(
          /^(?![1-9])0/g,
          ""
        );
        i++;
      }

      copiedForm.cpfServidor =
        copiedForm.cpfServidor === null
          ? null
          : Number(
              copiedForm.cpfServidor
                .toString()
                .replace(/\./g, "")
                .replace(/-/g, "")
            );

      copiedForm.numTitEleitor =
        copiedForm.numTitEleitor === null
          ? null
          : copiedForm.numTitEleitor.toString().replace(/\./g, "");

      copiedForm.pisPasep =
        copiedForm.pisPasep === null
          ? null
          : copiedForm.pisPasep
              .toString()
              .replace(/\./g, "")
              .replace(/-/g, "");

      Object.keys(copiedForm).map(key => {
        return (copiedForm[key] =
          copiedForm[key] === null
            ? null
            : copiedForm[key].toString().toUpperCase());
      });

      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            //console.log(copiedForm.siapeServidor);
            let response = services.servidor_publico.updateServidorPublicoAdmin(
              { id: id },
              copiedForm
            );
            ////console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionPOSTServidorPublico({ dispatch }, payload) {
      let papel = payload.papel;
      let copiedForm = Object.assign({}, payload.form);
      delete copiedForm.nomeEntidade;
      ////console.log(copiedForm);
      copiedForm.cpfServidor =
        copiedForm.cpfServidor === null
          ? null
          : Number(
              copiedForm.cpfServidor
                .toString()
                .replace(/\./g, "")
                .replace(/-/g, "")
            );
      copiedForm.numTitEleitor =
        copiedForm.numTitEleitor === null
          ? null
          : copiedForm.numTitEleitor.toString().replace(/\./g, "");

      copiedForm.pisPasep =
        copiedForm.pisPasep === null
          ? null
          : copiedForm.pisPasep
              .toString()
              .replace(/\./g, "")
              .replace(/-/g, "");

      Object.keys(copiedForm).map(key => {
        return (copiedForm[key] =
          copiedForm[key] === null
            ? null
            : copiedForm[key].toString().toUpperCase());
      });

      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.servidor_publico.createServidorPublicoAdmin(
              copiedForm
            );
            ////console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionDELETEServidorPublico({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.servidor_publico.deleteServidorPublicoByIdAdmin(
              { id: id }
            );
            ////console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    async ActionSubmitServidorAllData({ dispatch }, payload) {
      let papel = payload.papel;
      let responses = {};
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            if (
              this.state.servidor_publico.servidor_publico !== null &&
              this.state.servidor_publico.servidor_publico !== ""
            ) {
              let form = this.state.servidor_publico.servidor_publico;
              let i = 0;
              while (i < form.siapeServidor.length) {
                form.siapeServidor = form.siapeServidor.replace(
                  /^(?![1-9])0/g,
                  ""
                );
                i++;
              }
              //console.log("CALL ActionSubmitServidorAllData");
              let response = dispatch("ActionPOSTServidorPublico", {
                papel,
                form
              })
                .then(res => {
                  return new Promise(async (resolve, reject) => {
                    let idServidor = null;
                    responses = { responseServidor: res };
                    if (res.status === 200) {
                      if (res.body.mensagem) {
                        this.mensagem = res.body.mensagem;
                        if (this.mensagem.includes("não")) {
                          return res;
                        }
                      }
                      idServidor = res.body.idServidor;
                      if (
                        this.state.dependente.dependente !== null &&
                        this.state.dependente.dependente !== "" &&
                        typeof this.state.dependente.dependente.length !==
                          "undefined"
                      ) {
                        //let promiseDep = new Promise(async (resolve, reject) => {
                        this.state.dependente.dependente.map(dep => {
                          Object.keys(dep).map(key => {
                            if (key === "idEntidade") {
                              dep[
                                key
                              ] = this.state.servidor_publico.servidor_publico.idEntidade;
                            }
                            return dep[key];
                          });
                        });
                        let responseDependente = [];
                        this.state.dependente.dependente.map(dep => {
                          if (dep.nomeCompleto !== "" && !dep.idDependente) {
                            //console.log("CALL Dependente");
                            let dependente = Object.assign({}, dep);
                            delete dependente.parentesco;
                            dependente = {
                              ...dependente,
                              servidorPublico: idServidor,
                              parentesco: dep.parentesco
                            };
                            let responseDep = dispatch(
                              "dependente/ActionPOSTDependente",
                              {
                                papel,
                                dependente
                              },
                              { root: true }
                            )
                              .then(res => {
                                responseDependente.push(res);
                              })
                              .catch(err => {
                                responseDependente.push(err);
                              });
                          } else if (dep.idDependente) {
                            //console.log("CALL Update Dependente");
                            let servidorDependente = {
                              dependente: dep.idDependente,
                              servidorPublico: idServidor,
                              parentesco: dep.parentesco
                            };
                            let responseDep = dispatch(
                              "dependente/ActionPOSTServidorDependente",
                              {
                                papel,
                                servidorDependente
                              },
                              { root: true }
                            )
                              .then(res => {
                                responseDependente.push(res);
                              })
                              .catch(err => {
                                responseDependente.push(err);
                              });
                          }
                        });
                        //console.log(responseDependente);
                        responses = {
                          ...responses,
                          responseDependente: responseDependente
                        };
                      }
                      //let promiseEnd = new Promise(async (resolve, reject) => {
                      if (
                        this.state.endereco.endereco !== null &&
                        this.state.endereco.endereco !== "" &&
                        typeof this.state.endereco.endereco.length !==
                          "undefined"
                      ) {
                        let responseEndereco = [];
                        this.state.endereco.endereco.map(end => {
                          if (end.logradouro !== "") {
                            //console.log("CALL Endereco");
                            let endereco = end;
                            endereco = {
                              ...endereco,
                              servidorPublico: idServidor
                            };
                            let responseEnd = dispatch(
                              "endereco/ActionPOSTEndereco",
                              {
                                papel,
                                endereco
                              },
                              { root: true }
                            )
                              .then(res => {
                                responseEndereco.push(res);
                              })
                              .catch(err => {
                                responseEndereco.push(err);
                              });
                          }
                        });
                        //console.log(responseEndereco);
                        responses = {
                          ...responses,
                          responseEndereco: responseEndereco
                        };
                        //resolve(responses);
                      }
                      //});
                      //await Promise.all([promiseEnd]);
                      if (
                        this.state.telefone.telefone !== null &&
                        this.state.telefone.telefone !== "" &&
                        typeof this.state.telefone.telefone.length !==
                          "undefined"
                      ) {
                        let responseTelefone = [];
                        this.state.telefone.telefone.map(tel => {
                          if (tel.numero !== "") {
                            //console.log("CALL Telefone");
                            let telefone = tel;
                            telefone = {
                              ...telefone,
                              servidorPublico: idServidor
                            };
                            let responseTel = dispatch(
                              "telefone/ActionPOSTTelefone",
                              {
                                papel,
                                telefone
                              },
                              { root: true }
                            )
                              .then(res => {
                                responseTelefone.push(res);
                              })
                              .catch(err => {
                                responseTelefone.push(err);
                              });
                          }
                        });
                        //console.log(responseTelefone);
                        responses = {
                          ...responses,
                          responseTelefone: responseTelefone
                        };
                      }
                      if (
                        this.state.email.email !== null &&
                        this.state.email.email !== "" &&
                        typeof this.state.email.email.length !== "undefined"
                      ) {
                        let responseEmail = [];
                        this.state.email.email.map(e => {
                          if (e.mail !== "") {
                            //console.log("CALL Email");
                            let email = e;
                            email = {
                              ...email,
                              servidorPublico: idServidor
                            };
                            let responseE = dispatch(
                              "email/ActionPOSTEmail",
                              {
                                papel,
                                email
                              },
                              { root: true }
                            )
                              .then(res => {
                                responseEmail.push(res);
                              })
                              .catch(err => {
                                responseEmail.push(err);
                              });
                          }
                        });
                        //console.log(responseEmail);
                        responses = {
                          ...responses,
                          responseEmail: responseEmail
                        };
                      }
                      //console.log(responses);
                      resolve(responses);
                    } else {
                      reject(responses);
                    }
                  });
                })
                .catch(err => {
                  responses = { responseServidor: err };
                  return responses;
                });
              //console.log(response);
              resolve(response);
            } else {
              ////console.log(response);
              return reject(
                "não foi possivel ler os dados do Servidor no cadastro."
              );
            }
          } catch (err) {
            console.error(err);
            return reject(err);
          }
        });
      }
    }
  },
  mutations: {
    SET_SERVIDOR_PUBLICO(state, payload) {
      state.servidor_publico = payload;
    }
  },
  getters: {
    servidorPublicoState: state => {
      return state.servidor_publico;
    }
  }
};
