import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout.vue";
import AuthLayout from "@/pages/Dashboard/Pages/AuthLayout.vue";
// Dashboard pages
import Dashboard from "@/pages/Dashboard/Dashboard.vue";

// Pages
//import User from "@/pages/Dashboard/Pages/UserProfile.vue";
//import EditUser from "@/pages/Dashboard/Pages/EditUserProfile.vue";
import Settings from "@/pages/Dashboard/Pages/Settings.vue";
//import Pricing from "@/pages/Dashboard/Pages/Pricing.vue";
//import TimeLine from "@/pages/Dashboard/Pages/TimeLinePage.vue";
//import RtlSupport from "@/pages/Dashboard/Pages/RtlSupport.vue";
//import Login from "@/modules/auth/routes.js";
import { routes as auth } from "../modules/auth";
import { routes as user } from "../modules/user";
import { routes as entidade } from "../modules/entidade";
import { routes as papel } from "../modules/papel";
import { routes as servidor_publico } from "../modules/servidor_publico";
import { routes as pensionista } from "../modules/pensionista";
import { routes as importar } from "../modules/importar";
import { routes as arquivos } from "../modules/arquivo";
import { routes as log_atividades } from "../modules/log_atividades";
import { routes as ficha_financeira } from "../modules/ficha_financeira";

//import Register from "@/pages/Dashboard/Pages/Register.vue";
//import Lock from "@/pages/Dashboard/Pages/Lock.vue";

// Components pages
//import Buttons from "@/pages/Dashboard/Components/Buttons.vue";
//import GridSystem from "@/pages/Dashboard/Components/GridSystem.vue";
//import Panels from "@/pages/Dashboard/Components/Panels.vue";
//import SweetAlert from "@/pages/Dashboard/Components/SweetAlert.vue";
//import Notifications from "@/pages/Dashboard/Components/Notifications.vue";
//import Icons from "@/pages/Dashboard/Components/Icons.vue";
//import Typography from "@/pages/Dashboard/Components/Typography.vue";

// Forms pages
//import RegularForms from "@/pages/Dashboard/Forms/RegularForms.vue";
//import ExtendedForms from "@/pages/Dashboard/Forms/ExtendedForms.vue";
//import ValidationForms from "@/pages/Dashboard/Forms/ValidationForms.vue";
//import Wizard from "@/pages/Dashboard/Forms/Wizard.vue";

// TableList pages
//import RegularTables from "@/pages/Dashboard/Tables/RegularTables.vue";
//import ExtendedTables from "@/pages/Dashboard/Tables/ExtendedTables.vue";
//import PaginatedTables from "@/pages/Dashboard/Tables/PaginatedTables.vue";

// Maps pages
//import GoogleMaps from "@/pages/Dashboard/Maps/GoogleMaps.vue";
//import FullScreenMap from "@/pages/Dashboard/Maps/FullScreenMap.vue";
//import VectorMaps from "@/pages/Dashboard/Maps/VectorMaps.vue";

// Calendar
//import Calendar from "@/pages/Dashboard/Calendar.vue";
// Charts
//import Charts from "@/pages/Dashboard/Charts.vue";
//import Widgets from "@/pages/Dashboard/Widgets.vue";

/*let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  name: "Components",
  children: [
    {
      path: "buttons",
      name: "Buttons",
      components: { default: Buttons }
    },
    {
      path: "grid-system",
      name: "Grid System",
      components: { default: GridSystem }
    },
    {
      path: "panels",
      name: "Panels",
      components: { default: Panels }
    },
    {
      path: "sweet-alert",
      name: "Sweet Alert",
      components: { default: SweetAlert }
    },
    {
      path: "notifications",
      name: "Notifications",
      components: { default: Notifications }
    },
    {
      path: "icons",
      name: "Icons",
      components: { default: Icons }
    },
    {
      path: "typography",
      name: "Typography",
      components: { default: Typography }
    }
  ]
};*/

/*let formsMenu = {
  path: "/forms",
  component: DashboardLayout,
  redirect: "/forms/regular",
  name: "Forms",
  children: [
    {
      path: "regular",
      name: "Regular Forms",
      components: { default: RegularForms }
    },
    {
      path: "extended",
      name: "Extended Forms",
      components: { default: ExtendedForms }
    },
    {
      path: "validation",
      name: "Validation Forms",
      components: { default: ValidationForms }
    },
    {
      path: "wizard",
      name: "Wizard",
      components: { default: Wizard }
    }
  ]
};*/

/*let tablesMenu = {
  path: "/table-list",
  component: DashboardLayout,
  redirect: "/table-list/regular",
  name: "Tables",
  children: [
    {
      path: "regular",
      name: "Regular Tables",
      components: { default: RegularTables }
    },
    {
      path: "extended",
      name: "Extended Tables",
      components: { default: ExtendedTables }
    },
    {
      path: "paginated",
      name: "Pagianted Tables",
      components: { default: PaginatedTables }
    }
  ]
};*/

/*let mapsMenu = {
  path: "/maps",
  component: DashboardLayout,
  name: "Maps",
  redirect: "/maps/google",
  children: [
    {
      path: "google",
      name: "Google Maps",
      components: { default: GoogleMaps }
    },
    {
      path: "full-screen",
      name: "Full Screen Map",
      meta: {
        hideContent: true,
        hideFooter: true,
        navbarAbsolute: true
      },
      components: { default: FullScreenMap }
    },
    {
      path: "vector-map",
      name: "Vector Map",
      components: { default: VectorMaps }
    }
  ]
};*/

let pagesMenu = {
  path: "/pages",
  component: DashboardLayout,
  name: "Pages",
  redirect: "/pages/usuario",
  children: [
    user[0],
    user[1],
    user[2],
    /*{
      path: "user",
      name: "User Page",
      components: { default: user }
    },*/
    /*{
      path: "edit_user",
      name: "Edit User Page",
      components: { default: EditUser }
    },*/
    {
      path: "settings",
      name: "Configuração",
      components: { default: Settings }
    }
    /* {
      path: "timeline",
      name: "Timeline Page",
      components: { default: TimeLine }
    }
    {
      path: "rtl",
      name: "وحة القيادة",
      meta: {
        rtlActive: true
      },
      components: { default: RtlSupport }
    }*/
  ]
};

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    /*{
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/register",
      name: "Register",
      component: Register
    }*/
    /*{
      path: "/pricing",
      name: "Pricing",
      component: Pricing
    },
    {
      path: "/lock",
      name: "Lock",
      component: Lock
    }*/
  ]
};

authPages.children.push(...auth);

const router = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "Home"
  },
  //componentsMenu,
  //formsMenu,
  //tablesMenu,
  //mapsMenu,
  pagesMenu,
  authPages,
  {
    path: "/",
    component: DashboardLayout,
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        components: { default: Dashboard }
      },
      entidade[0],
      papel[0],
      papel[1],
      user[3],
      user[4],
      user[5],
      servidor_publico[0],
      servidor_publico[1],
      servidor_publico[2],
      servidor_publico[3],
      pensionista[0],
      pensionista[1],
      pensionista[2],
      pensionista[3],
      importar[0],
      importar[1],
      arquivos[2],
      log_atividades[0],
      ficha_financeira[0]
      /*{
        path: "calendar",
        name: "Calendar",
        components: { default: Calendar }
      },
      {
        path: "charts",
        name: "Charts",
        components: { default: Charts }
      },
      {
        path: "widgets",
        name: "Widgets",
        components: { default: Widgets }
      }*/
    ]
  }
];

export default router;
