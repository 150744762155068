export const importar = {
  path: "importar",
  name: "Importar",
  props: true,
  component: () => import(/* webpackChunkName: "importar" */ "./pages/Importar")
};

export const exportar_servidores = {
  path: "exportar_servidores",
  name: "Exportar Servidores",
  props: true,
  component: () =>
    import(/* webpackChunkName: "importar" */ "./pages/ExportarServidores")
};

export default [importar, exportar_servidores];
