export const novos_telefones = {
  path: "novos_telefones",
  name: "Novos Telefones",
  props: true,
  component: () =>
    import(/* webpackChunkName: "novos_telefones" */ "./pages/NovosTelefones")
};

export const telefones = {
  path: "telefones",
  name: "Telefones",
  props: true,
  component: () =>
    import(/* webpackChunkName: "enderecos" */ "./pages/Telefones")
};

export const novos_telefones_pensionista = {
  path: "novos_telefones_pensionista",
  name: "Novos Telefones Pensionista",
  props: true,
  component: () =>
    import(/* webpackChunkName: "novos_telefones" */ "./pages/NovosTelefones")
};

export const telefones_pensionista = {
  path: "telefones_pensionista",
  name: "Telefones Pensionista",
  props: true,
  component: () =>
    import(/* webpackChunkName: "enderecos" */ "./pages/Telefones")
};

export default [
  telefones,
  novos_telefones,
  novos_telefones_pensionista,
  telefones_pensionista
];
