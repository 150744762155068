export default {
  getPensionistaQuotaParteByIdAdmin: {
    method: "get",
    url: "api/v1/admin/pensionista_quota_parte{/id}"
  },
  deletePensionistaQuotaParteByIdAdmin: {
    method: "delete",
    url: "api/v1/admin/pensionista_quota_parte{/id}"
  },
  updatePensionistaQuotaParteAdmin: {
    method: "put",
    url: "api/v1/admin/pensionista_quota_parte{/id}"
  },
  createPensionistaQuotaParteAdmin: {
    method: "post",
    url: "api/v1/admin/pensionista_quota_parte/"
  },
  getQuotaParteFromPenAdmin: {
    method: "get",
    url: "api/v1/admin/pensionista_quota_parte/from{/id}"
  },
  getGetPensionistaQuotaPartePaginationAdmin: {
    method: "post",
    url: "api/v1/admin/pensionista_quota_parte/from{/id}/pagination"
  },
  getPensionistaQuotaPartesByQueryAdmin: {
    method: "get",
    url: "api/v1/admin/pensionista_quota_parte{/id}/search/query{?q*}"
  }
};
