export const servidores_publicos_historico_vinculos = {
  name: "Historico Vinculos",
  path: "/servidores_publicos/detalhe_servidor/:id/historico_vinculos",
  props: true,
  component: () =>
    import(
      /* webpackChunkName: "servidores_publicos/detalhe_servidor/:id/historico_vinculos" */ "./pages/HistoricoVinculos"
    )
};

export const novo_servidores_publicos_historico_vinculos = {
  name: "Novo Historico Vinculos",
  path: "/servidores_publicos/historico_vinculos",
  props: true,
  component: () =>
    import(
      /* webpackChunkName: "servidores_publicos/historico_vinculos" */ "./pages/HistoricoVinculos"
    )
};

export default [
  servidores_publicos_historico_vinculos,
  novo_servidores_publicos_historico_vinculos
];
