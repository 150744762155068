export const arquivos = {
  path: "arquivos",
  name: "Arquivos",
  props: true,
  component: () => import(/* webpackChunkName: "arquivos" */ "./pages/Arquivos")
};

export const arquivos_pensionista = {
  path: "arquivos_pensionista",
  name: "Arquivos Pensionista",
  props: true,
  component: () => import(/* webpackChunkName: "emails" */ "./pages/Arquivos")
};

export const arquivos_entidade = {
  path: "arquivos/entidade/:id",
  name: "Arquivos Entidade",
  props: true,
  component: () =>
    import(/* webpackChunkName: "arquivos" */ "./pages/ArquivosEntidade")
};

export default [arquivos, arquivos_pensionista, arquivos_entidade];
