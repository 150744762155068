export const novos_emails = {
  path: "novos_emails",
  name: "Novos Emails",
  props: true,
  component: () =>
    import(/* webpackChunkName: "novos_emails" */ "./pages/NovosEmails")
};

export const emails = {
  path: "emails",
  name: "Emails",
  props: true,
  component: () => import(/* webpackChunkName: "emails" */ "./pages/Emails")
};

export const novos_emails_pensionista = {
  path: "novos_emails_pensionista",
  name: "Novos Emails Pensionista",
  props: true,
  component: () =>
    import(/* webpackChunkName: "novos_emails" */ "./pages/NovosEmails")
};

export const emails_pensionista = {
  path: "emails_pensionista",
  name: "Emails Pensionista",
  props: true,
  component: () => import(/* webpackChunkName: "emails" */ "./pages/Emails")
};

export default [
  emails,
  novos_emails,
  novos_emails_pensionista,
  emails_pensionista
];
