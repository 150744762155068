import { store as auth } from "@/modules/auth";
import { store as user } from "@/modules/user";
import { store as entidade } from "@/modules/entidade";
import { store as papel } from "@/modules/papel";
import { store as servidor_pensionista } from "@/modules/servidor_pensionista";
import { store as servidor_publico } from "@/modules/servidor_publico";
import { store as pensionista } from "@/modules/pensionista";
import { store as dependente } from "@/modules/dependente";
import { store as endereco } from "@/modules/endereco";
import { store as telefone } from "@/modules/telefone";
import { store as email } from "@/modules/email";
import { store as arquivo } from "@/modules/arquivo";
import { store as pensionista_quotaparte } from "@/modules/pensionista_quotaparte";
import { store as importar } from "@/modules/importar";
import { store as historico_vinculos } from "@/modules/historico_vinculos";
import { store as log_atividades } from "@/modules/log_atividades";
import { store as ficha_financeira } from "@/modules/ficha_financeira";

export default {
  auth,
  user,
  entidade,
  papel,
  servidor_pensionista,
  servidor_publico,
  pensionista,
  dependente,
  endereco,
  telefone,
  email,
  arquivo,
  pensionista_quotaparte,
  importar,
  historico_vinculos,
  log_atividades,
  ficha_financeira
};
