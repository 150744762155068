export default {
  login: { method: "post", url: "login" },
  loadSession: { method: "get", url: "api/v1/me" },
  esqueciSenha: { method: "post", url: "esqueci_senha" },
  recuperarSenha: { method: "post", url: "recuperar_senha" },
  validarToken: { method: "get", url: "validate" },
  registro: { method: "post", url: "registro" },
  confirmarEmail: { method: "post", url: "confirmar_email" },
  perfilAtual: { method: "put", url: "api/v1/me/perfil_atual{/id}" },
  reenviarEmail: { method: "post", url: "reenviar_email" },
  solicitacaoCadastro: { method: "post", url: "solicitacao_cadastro" }
};
