import { routes as endereco } from "../endereco";
import { routes as telefone } from "../telefone";
import { routes as email } from "../email";
import { routes as pensionista_quotapartes } from "../pensionista_quotaparte";
import { routes as arquivo } from "../arquivo";

export const detalhe_pensionista = {
  path: "pensionistas/detalhe_pensionista/:id",
  name: "Detalhe Pensionista",
  props: true,
  redirect: { name: "Cadastro Pensionista" },
  component: () =>
    import(
      /* webpackChunkName: "detalhe_pensionista" */ "./pages/DetalhePensionista"
    ),
  children: [
    {
      name: "Cadastro Pensionista",
      path: "/pensionistas/detalhe_pensionista/:id/cadastro/",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "detalhe_pensionista/:id/cadastro" */ "./pages/Cadastro"
        )
    },
    {
      name: "Pensao",
      path: "/pensionistas/detalhe_pensionista/:id/pensao",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "detalhe_pensionista/:id/pensao" */ "./pages/Pensao"
        )
    },
    pensionista_quotapartes[0],
    endereco[3],
    telefone[3],
    email[3],
    arquivo[1]
  ]
};

export const pensionista = {
  path: "pensionistas",
  name: "Pensionistas",
  component: () =>
    import(/* webpackChunkName: "pensionista" */ "./pages/Pensionistas")
};

export const novo_pensionista = {
  path: "pensionistas/novo_pensionista",
  name: "Novo Pensionista",
  redirect: { name: "Novo Cadastro Pensionista" },
  props: true,
  component: () =>
    import(
      /* webpackChunkName: "novo_pensionista" */ "./pages/NovoPensionista"
    ),
  children: [
    {
      name: "Novo Cadastro Pensionista",
      path: "novo_cadastro_pensionista",
      component: () =>
        import(
          /* webpackChunkName: "novo_pensionista/cadastro" */ "./pages/NovoCadastro"
        )
    },
    {
      name: "Nova Pensao",
      path: "nova_pensao",
      component: () =>
        import(
          /* webpackChunkName: "novo_pensionista/vinculos" */ "./pages/NovaPensao"
        )
    },
    pensionista_quotapartes[1],
    endereco[2],
    telefone[2],
    email[2]
  ]
};

export const pensionista_view = {
  path: "pensionista_view/:id",
  name: "Pensionista Visualizar",
  props: true,
  component: () =>
    import(/* webpackChunkName: "pensionista" */ "./pages/PensionistaView")
};

export default [
  pensionista,
  novo_pensionista,
  detalhe_pensionista,
  pensionista_view
];
