import services from "@/http";
import store from "../../../store";

const namespaced = true;

export default {
  namespaced,
  state: {
    pasta: {},
    arquivo: {},
    message: null
  },
  actions: {
    ActionGetArquivoById({ dispatch }, payload) {
      //console.log(payload);
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.arquivo.getArquivoByIdAdmin({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetFromEntidadeById({ dispatch }, payload) {
      //console.log(payload);
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN_ENTIDADE") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.arquivo.getArquivoFromEntidadeByIdAdminEntidade(
              {
                id: id
              }
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionSetARQUIVO({ commit }, payload) {
      commit("SET_ARQUIVO", payload);
    },
    ActionSetPASTA({ commit }, payload) {
      commit("SET_PASTA", payload);
    },
    ActionPUTArquivo({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      let copiedForm = Object.assign({}, payload.arquivo);

      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.arquivo.updateArquivoAdmin(
              { id: id },
              copiedForm
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionPOSTArquivo({ dispatch }, payload) {
      let papel = payload.papel;
      let copiedForm = Object.assign({}, payload.arquivo);

      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.arquivo.createArquivoAdmin(copiedForm);
            console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetArquivoFromServ({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.arquivo.getArquivoFromServAdmin({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "SERVIDOR") {
        return new Promise(async (resolve, reject) => {
          try {
            let idEntidade = payload.idEntidade;
            let response = services.arquivo.getArquivoFromServUser({
              id: id,
              idEntidade: idEntidade
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err);
            return reject(err.status);
          }
        });
      } else if (papel === "ADMIN_ENTIDADE") {
        return new Promise(async (resolve, reject) => {
          try {
            let idEntidade = payload.idEntidade;
            let response = services.arquivo.getArquivoFromServAdminEntidade({
              id: id,
              idEntidade: idEntidade
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetArquivoFromPen({ dispatch }, payload) {
      //console.log(payload);
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.arquivo.getArquivoFromPenAdmin({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "PENSIONISTA") {
        return new Promise(async (resolve, reject) => {
          let idEntidade = payload.idEntidade;
          try {
            let response = services.arquivo.getArquivoFromPenUser({
              id: id,
              idEntidade: idEntidade
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "ADMIN_ENTIDADE") {
        return new Promise(async (resolve, reject) => {
          let idEntidade = payload.idEntidade;
          try {
            let response = services.arquivo.getArquivoFromPenAdminEntidade({
              id: id,
              idEntidade: idEntidade
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionDELETEArquivo({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.arquivo.deleteArquivoByIdAdmin({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionDELETEPasta({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.arquivo.deletePastaByIdAdmin({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionPUTPasta({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      let copiedForm = Object.assign({}, payload.nomePasta);
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.arquivo.updateNomePasta(
              { id: id },
              copiedForm
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetArquivoVersoesById({ dispatch }, payload) {
      //console.log(payload);
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.arquivo.getArquivoVersoesByIdAdmin({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    }
  },
  mutations: {
    SET_ARQUIVO(state, payload) {
      state.arquivo = payload;
    },
    SET_PASTA(state, payload) {
      state.pasta = payload;
    }
  },
  getters: {
    arquivoState: state => {
      return state.arquivo;
    },
    pastaState: state => {
      return state.pasta;
    }
  }
};
