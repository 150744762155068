import { routes as dependente } from "../dependente";
import { routes as endereco } from "../endereco";
import { routes as telefone } from "../telefone";
import { routes as email } from "../email";
import { routes as arquivo } from "../arquivo";
import { routes as historico_vinculos } from "../historico_vinculos";

export const detalhe_servidor = {
  path: "servidores_publicos/detalhe_servidor/:id",
  name: "Detalhe Servidor",
  props: true,
  redirect: { name: "Cadastro" },
  component: () =>
    import(
      /* webpackChunkName: "detalhe_servidor" */ "./pages/DetalheServidor"
    ),
  children: [
    {
      name: "Cadastro",
      path: "/servidores_publicos/detalhe_servidor/:id/cadastro/",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "detalhe_servidor/:id/cadastro" */ "./pages/Cadastro"
        )
    },
    endereco[0],

    telefone[0],

    email[0],

    arquivo[0],

    {
      name: "Vinculos",
      path: "/servidores_publicos/detalhe_servidor/:id/vinculos",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "detalhe_servidor/:id/vinculos" */ "./pages/Vinculos"
        )
    },
    dependente[0],

    historico_vinculos[0]
  ]
};
export const servidores_publicos = {
  path: "servidores_publicos",
  name: "Servidores",
  component: () =>
    import(
      /* webpackChunkName: "servidores_publicos" */ "./pages/ServidoresPublicos"
    )
};

export const novo_servidor = {
  path: "servidores_publicos/novo_servidor",
  name: "Novo Servidor",
  redirect: { name: "Novo Cadastro" },
  props: true,
  component: () =>
    import(/* webpackChunkName: "novo_servidor" */ "./pages/NovoServidor"),
  children: [
    {
      name: "Novo Cadastro",
      path: "novo_cadastro",
      component: () =>
        import(
          /* webpackChunkName: "novo_servidor/cadastro" */ "./pages/NovoCadastro"
        )
    },
    endereco[1],

    telefone[1],

    email[1],

    {
      name: "Novos Vinculos",
      path: "novos_vinculos",
      component: () =>
        import(
          /* webpackChunkName: "novo_servidor/vinculos" */ "./pages/NovosVinculos"
        )
    },

    dependente[1]
  ]
};

export const servidor_view = {
  path: "servidor_view/:id",
  name: "Servidor Visualizar",
  props: true,
  component: () =>
    import(/* webpackChunkName: "pensionista" */ "./pages/ServidorView")
};

export default [
  servidores_publicos,
  novo_servidor,
  detalhe_servidor,
  servidor_view
];
