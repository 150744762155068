export default {
  getUsuarioByIdAdmin: {
    method: "get",
    url: "api/v1/admin/usuario{/id}"
  },
  getUsuariosAllAdmin: {
    method: "get",
    url: "api/v1/admin/usuario/"
  },
  deleteUsuarioByIdAdmin: {
    method: "delete",
    url: "api/v1/admin/usuario{/id}"
  },
  updateUsuarioPerfilAdmin: {
    method: "put",
    url: "api/v1/admin/usuario/profile{/id}"
  },
  updateUsuarioAdmin: {
    method: "put",
    url: "api/v1/admin/usuario{/id}"
  },
  createUsuarioAdmin: {
    method: "post",
    url: "api/v1/admin/usuario/"
  },
  updateChangePassAdmin: {
    method: "put",
    url: "api/v1/admin/usuario/change_pass{/id}"
  },
  getUsuariosAssingmentPapelAdmin: {
    method: "get",
    url: "api/v1/admin/usuario/assingment_papel/all/"
  },
  getIsPensionistaOrServidorAdmin: {
    method: "get",
    url: "api/v1/admin/usuario/servidor_or_pensionista{/id}"
  },
  deleteUsuarioEntidadeByIdAdmin: {
    method: "delete",
    url: "api/v1/admin/usuario_entidade{/id}"
  },
  createUsuarioEntidadeAdmin: {
    method: "post",
    url: "api/v1/admin/usuario_entidade{/id}"
  },
  getUsuarioEntidadeById: {
    method: "get",
    url: "api/v1/admin/usuario_entidade{/id}"
  },
  getUsuarioByIdUser: {
    method: "post",
    url: "api/v1/user/usuario/"
  },
  updateUsuarioPerfilUser: {
    method: "put",
    url: "api/v1/user/usuario/profile{/id}"
  },
  updateChangePassUser: {
    method: "put",
    url: "api/v1/user/usuario/change_pass{/id}"
  },
  getUsuariosAllAdminEntidade: {
    method: "post",
    url: "api/v1/admin_entidade/usuario/get/all/"
  },
  createUsuarioAdminEntidade: {
    method: "post",
    url: "api/v1/admin_entidade/usuario/"
  },
  deleteUsuarioByIdAdminEntidade: {
    method: "delete",
    url: "api/v1/admin_entidade/usuario{/id}"
  },
  getUsuarioByIdAdminEntidade: {
    method: "get",
    url: "api/v1/admin_entidade/usuario{/id}"
  },
  getIsPensionistaOrServidorAdminEntidade: {
    method: "get",
    url: "api/v1/admin_entidade/usuario/servidor_or_pensionista{/id}"
  },
  updateUsuarioAdminEntidade: {
    method: "put",
    url: "api/v1/admin_entidade/usuario{/id}"
  },
  deleteUsuarioEntidadeByIdAdminEntidade: {
    method: "delete",
    url: "api/v1/admin_entidade/usuario_entidade{/id}"
  },
  createUsuarioEntidadeAdminEntidade: {
    method: "post",
    url: "api/v1/admin_entidade/usuario_entidade{/id}"
  }
};
