import state from "./state";
import mutations from "./mutations";
import * as actions from "./actions";
import * as getters from "./getters";

//const getters = (hasToken = ({ jwt }) => !!jwt);

const namespaced = true;

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced
};
