import services from "@/http";
import store from "../../../store";

const namespaced = true;

export default {
  namespaced,
  state: {
    usuario: {},
    message: null
  },
  actions: {
    ActionGetUserById({ dispatch }, payload) {
      //console.log(payload);
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.user.getUsuarioByIdAdmin({ id: id });
            //console.log(response);
            return resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        }).then(res => {
          return new Promise((resolve, reject) => {
            try {
              //console.log(res.body);
              dispatch("ActionSetUser", res.body);
              return resolve(res);
            } catch (err) {
              //console.log(err.status);
              return reject(err.status);
            }
          });
        });
      } else if (papel === "SERVIDOR" || papel === "PENSIONISTA") {
        return new Promise(async (resolve, reject) => {
          try {
            let idEntidade = payload.idEntidade;
            let response = services.user.getUsuarioByIdUser({
              id: id,
              idEntidade: idEntidade
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "ADMIN_ENTIDADE") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.user.getUsuarioByIdAdminEntidade({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        }).then(res => {
          return new Promise((resolve, reject) => {
            try {
              //console.log(res.body);
              dispatch("ActionSetUser", res.body);
              return resolve(res);
            } catch (err) {
              //console.log(err.status);
              return reject(err.status);
            }
          });
        });
      }
    },
    ActionGetUserAll({ dispatch }, payload) {
      //console.log(payload);
      //let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.user.getUsuariosAllAdmin();
            //console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "ADMIN_ENTIDADE") {
        return new Promise(async (resolve, reject) => {
          try {
            //console.log(payload);
            let idEntidade = payload.idEntidade;
            let response = services.user.getUsuariosAllAdminEntidade({
              idEntidade: idEntidade
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetIsPensionistaOrServidor({ dispatch }, payload) {
      //console.log(payload);
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.user.getIsPensionistaOrServidorAdmin({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "ADMIN_ENTIDADE") {
        return new Promise(async (resolve, reject) => {
          try {
            let idEntidade = payload.idEntidade;
            let response = services.user.getIsPensionistaOrServidorAdminEntidade(
              {
                id: id
              }
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetUserAssingmentPapelAll({ dispatch }, payload) {
      //console.log(payload);
      //let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.user.getUsuariosAssingmentPapelAdmin();
            //console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionSetUser({ commit }, payload) {
      commit("SET_USER", payload);
    },
    ActionPUTUser({ dispatch }, payload) {
      let papel = payload.papel;
      let id = payload.id;
      let copiedForm = Object.assign({}, payload.form);
      //console.log(payload.form);
      Object.keys(copiedForm).map(key => {
        if (key === "nomeCompleto") {
          return (copiedForm[key] =
            copiedForm[key] === null
              ? null
              : copiedForm[key].toString().toUpperCase());
        }
        return copiedForm[key];
      });
      copiedForm.cpfUsuario =
        copiedForm.cpfUsuario === null
          ? null
          : copiedForm.cpfUsuario
              .toString()
              .replace(/\./g, "")
              .replace(/-/g, "");

      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.user.updateUsuarioAdmin(
              { id: id },
              copiedForm
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "ADMIN_ENTIDADE") {
        return new Promise(async (resolve, reject) => {
          try {
            // console.log(copiedForm);
            let response = services.user.updateUsuarioAdminEntidade(
              { id: id },
              copiedForm
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionPUTUsuarioPerfil({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      let copiedForm = Object.assign({}, payload.form);
      delete copiedForm.nomeEntidade;
      delete copiedForm.nomePapel;
      delete copiedForm.idEntidade;
      delete copiedForm.idPapel;
      delete copiedForm.cpfUsuario;

      Object.keys(copiedForm).map(key => {
        if (key === "nomeCompleto") {
          return (copiedForm[key] =
            copiedForm[key] === null
              ? null
              : copiedForm[key].toString().toUpperCase());
        }
        return copiedForm[key];
      });

      /*copiedForm.cpfUsuario =
        copiedForm.cpfUsuario === null
          ? null
          : copiedForm.cpfUsuario
              .toString()
              .replace(/\./g, "")
              .replace(/-/g, "");
      */

      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            //console.log(copiedForm);
            let response = services.user.updateUsuarioPerfilAdmin(
              { id: id },
              copiedForm
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (
        papel === "SERVIDOR" ||
        papel === "PENSIONISTA" ||
        papel === "ADMIN_ENTIDADE"
      ) {
        return new Promise(async (resolve, reject) => {
          try {
            // console.log(copiedForm);
            let response = services.user.updateUsuarioPerfilUser(
              { id: id },
              copiedForm
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionPUTChangePass({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      //console.log(payload);
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.user.updateChangePassAdmin(
              { id: id },
              payload.form
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "SERVIDOR" || papel === "PENSIONISTA") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.user.updateChangePassUser(
              { id: id },
              payload.form
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionPOSTUser({ dispatch }, payload) {
      let papel = payload.papel;
      let copiedForm = Object.assign({}, payload.form);
      Object.keys(copiedForm).map(key => {
        if (key === "nomeCompleto") {
          return (copiedForm[key] =
            copiedForm[key] === null
              ? null
              : copiedForm[key].toString().toUpperCase());
        }
        return copiedForm[key];
      });
      copiedForm.cpfUsuario =
        copiedForm.cpfUsuario === null
          ? null
          : copiedForm.cpfUsuario
              .toString()
              .replace(/\./g, "")
              .replace(/-/g, "");

      //console.log(copiedForm);
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.user.createUsuarioAdmin(copiedForm);
            //console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "ADMIN_ENTIDADE") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.user.createUsuarioAdminEntidade(copiedForm);
            //console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionDELETEUser({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.user.deleteUsuarioByIdAdmin({ id: id });
            //console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "ADMIN_ENTIDADE") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.user.deleteUsuarioByIdAdminEntidade({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionPOSTUsuarioEntidade({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      let form = payload.form;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.user.createUsuarioEntidadeAdmin(
              { id: id },
              form
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "ADMIN_ENTIDADE") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.user.createUsuarioEntidadeAdminEntidade(
              { id: id },
              form
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionDELETEUsuarioEntidade({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.user.deleteUsuarioEntidadeByIdAdmin({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "ADMIN_ENTIDADE") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.user.deleteUsuarioEntidadeByIdAdminEntidade(
              {
                id: id
              }
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetUsuarioEntidadeById({ dispatch }, payload) {
      //console.log(payload);
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.user.getUsuarioEntidadeById({ id: id });
            //console.log(response);
            return resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "ADMIN_ENTIDADE") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.user.getUsuarioEntidadeById({ id: id });
            //console.log(response);
            return resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "SERVIDOR") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.user.getUsuarioEntidadeById({ id: id });
            //console.log(response);
            return resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "PENSIONISTA") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.user.getUsuarioEntidadeById({ id: id });
            //console.log(response);
            return resolve(response);
          } catch (err) {
            //console.log(err.status);
            return reject(err.status);
          }
        });
      }
    }
  },
  mutations: {
    SET_USER(state, payload) {
      state.usuario = payload;
    }
  },
  getters: {
    success(state, payload) {
      state.usuario = payload;
    }
  }
};
