import services from "@/http";
import store from "../../../store";

const namespaced = true;

export default {
  namespaced,
  state: {
    endereco: {},
    message: null
  },
  actions: {
    ActionGetEnderecoById({ dispatch }, payload) {
      //console.log(payload);
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.endereco.getEnderecoByIdAdmin({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetEnderecoAll({ dispatch }, payload) {
      //console.log(payload);
      //let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.endereco.getServidoresPublicoAllAdmin();
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionSetENDERECO({ commit }, payload) {
      commit("SET_ENDERECO", payload);
    },
    ActionPUTEndereco({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      let copiedForm = Object.assign({}, payload.endereco);
      copiedForm.cep =
        copiedForm.cep === null
          ? null
          : copiedForm.cep.toString().replace(/-/g, "");

      Object.keys(copiedForm).map(key => {
        return (copiedForm[key] =
          copiedForm[key] === null
            ? null
            : copiedForm[key].toString().toUpperCase());
      });

      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.endereco.updateEnderecoAdmin(
              { id: id },
              copiedForm
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionPOSTEndereco({ dispatch }, payload) {
      let papel = payload.papel;
      let copiedForm = Object.assign({}, payload.endereco);

      copiedForm.cep =
        copiedForm.cep === null
          ? null
          : copiedForm.cep.toString().replace(/-/g, "");

      Object.keys(copiedForm).map(key => {
        return (copiedForm[key] =
          copiedForm[key] === null
            ? null
            : copiedForm[key].toString().toUpperCase());
      });

      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.endereco.createEnderecoAdmin(copiedForm);
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionDELETEEndereco({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.endereco.deleteEnderecoByIdAdmin({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetEnderecoFromServ({ dispatch }, payload) {
      //console.log(payload);
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.endereco.getEnderecoFromServAdmin({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "ADMIN_ENTIDADE") {
        return new Promise(async (resolve, reject) => {
          try {
            let idEntidade = payload.idEntidade;
            let response = services.endereco.getEnderecoFromServAdminEntidade({
              id: id,
              idEntidade: idEntidade
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetEnderecoFromPen({ dispatch }, payload) {
      //console.log(payload);
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.endereco.getEnderecoFromPenAdmin({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "ADMIN_ENTIDADE") {
        return new Promise(async (resolve, reject) => {
          try {
            let idEntidade = payload.idEntidade;
            let response = services.endereco.getEnderecoFromPenAdminEntidade({
              id: id,
              idEntidade: idEntidade
            });
            console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetCEP({ dispatch }, payload) {
      //console.log(payload);
      let id = payload.cep;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.endereco.getCEP({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "ADMIN_ENTIDADE") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.endereco.getCEP({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    }
  },
  mutations: {
    SET_ENDERECO(state, payload) {
      state.endereco = payload;
    }
  },
  getters: {
    enderecoState: state => {
      return state.endereco;
    }
  }
};
