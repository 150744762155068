export const novos_enderecos = {
  path: "novos_enderecos",
  name: "Novos Enderecos",
  props: true,
  component: () =>
    import(/* webpackChunkName: "novos_enderecos" */ "./pages/NovosEnderecos")
};

export const enderecos = {
  path: "enderecos",
  name: "Enderecos",
  props: true,
  component: () =>
    import(/* webpackChunkName: "enderecos" */ "./pages/Enderecos")
};

export const novos_enderecos_pensionista = {
  path: "novos_enderecos_pensionista",
  name: "Novos Enderecos Pensionista",
  props: true,
  component: () =>
    import(/* webpackChunkName: "novos_enderecos" */ "./pages/NovosEnderecos")
};

export const enderecos_pensionista = {
  path: "enderecos_pensionista",
  name: "Enderecos Pensionista",
  props: true,
  component: () =>
    import(/* webpackChunkName: "enderecos" */ "./pages/Enderecos")
};

export default [
  enderecos,
  novos_enderecos,
  novos_enderecos_pensionista,
  enderecos_pensionista
];
