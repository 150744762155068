import services from "@/http";
import store from "../../../store";

const namespaced = true;

export default {
  namespaced,
  state: {
    telefone: {},
    message: null
  },
  actions: {
    ActionGetTelefoneById({ dispatch }, payload) {
      //console.log(payload);
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.telefone.getTelefoneByIdAdmin({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetTelefoneAll({ dispatch }, payload) {
      //console.log(payload);
      //let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.telefone.getServidoresPublicoAllAdmin();
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionSetTELEFONE({ commit }, payload) {
      commit("SET_TELEFONE", payload);
    },
    ActionPUTTelefone({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      let copiedForm = Object.assign({}, payload.telefone);
      if (copiedForm.codigoPais.toString() === "55") {
        copiedForm.dd =
          copiedForm.dd === null
            ? null
            : Number(
                copiedForm.numero
                  .toString()
                  .split(/\(/)[1]
                  .split(/\)/)[0]
              );

        copiedForm.numero =
          copiedForm.numero === null
            ? null
            : Number(
                copiedForm.numero
                  .toString()
                  .split(/\(/)[1]
                  .split(/\)/)[1]
                  .replace(/\./g, "")
                  .replace(/-/g, "")
                  .replace(/\(/g, "")
                  .replace(/\)/g, "")
              );
      } else {
        copiedForm.dd = null;
        copiedForm.numero = Number(copiedForm.numero);
      }
      delete copiedForm.codigoPaisLegend;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.telefone.updateTelefoneAdmin(
              { id: id },
              copiedForm
            );
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionPOSTTelefone({ dispatch }, payload) {
      let papel = payload.papel;
      let copiedForm = Object.assign({}, payload.telefone);
      if (copiedForm.codigoPais.toString() === "55") {
        copiedForm.dd =
          copiedForm.dd === null
            ? null
            : Number(
                copiedForm.numero
                  .toString()
                  .split(/\(/)[1]
                  .split(/\)/)[0]
              );

        copiedForm.numero =
          copiedForm.numero === null
            ? null
            : Number(
                copiedForm.numero
                  .toString()
                  .split(/\(/)[1]
                  .split(/\)/)[1]
                  .replace(/\./g, "")
                  .replace(/-/g, "")
                  .replace(/\(/g, "")
                  .replace(/\)/g, "")
              );
      } else {
        copiedForm.dd = null;
        copiedForm.numero = Number(copiedForm.numero);
      }

      delete copiedForm.codigoPaisLegend;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.telefone.createTelefoneAdmin(copiedForm);
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetTelefoneFromServ({ dispatch }, payload) {
      //console.log(payload);
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.telefone.getTelefoneFromServAdmin({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "ADMIN_ENTIDADE") {
        return new Promise(async (resolve, reject) => {
          try {
            let idEntidade = payload.idEntidade;
            let response = services.telefone.getTelefoneFromServAdminEntidade({
              id: id,
              idEntidade: idEntidade
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionGetTelefoneFromPen({ dispatch }, payload) {
      //console.log(payload);
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.telefone.getTelefoneFromPenAdmin({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      } else if (papel === "ADMIN_ENTIDADE") {
        return new Promise(async (resolve, reject) => {
          try {
            let idEntidade = payload.idEntidade;
            let response = services.telefone.getTelefoneFromPenAdminEntidade({
              id: id,
              idEntidade: idEntidade
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    },
    ActionDELETETelefone({ dispatch }, payload) {
      let id = payload.id;
      let papel = payload.papel;
      if (papel === "ADMIN") {
        return new Promise(async (resolve, reject) => {
          try {
            let response = services.telefone.deleteTelefoneByIdAdmin({
              id: id
            });
            //console.log(response);
            resolve(response);
          } catch (err) {
            console.log(err.status);
            return reject(err.status);
          }
        });
      }
    }
  },
  mutations: {
    SET_TELEFONE(state, payload) {
      state.telefone = payload;
    }
  },
  getters: {
    telefoneState: state => {
      return state.telefone;
    }
  }
};
