export const novos_dependentes = {
  path: "novos_dependentes",
  name: "Novos Dependentes",
  props: true,
  component: () =>
    import(
      /* webpackChunkName: "novos_dependentes" */ "./pages/NovosDependentes"
    )
};

export const dependentes = {
  path: "dependentes",
  name: "Dependentes",
  props: true,
  component: () =>
    import(/* webpackChunkName: "dependentes/" */ "./pages/Dependentes")
};

export default [dependentes, novos_dependentes];
